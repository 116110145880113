@import "stylesheets/base";

.cookies-popup {
  position: fixed;
  bottom: 0;
  right: 50%;
  transform: translateX(50%);
  width: auto;
  padding: $spacing-400;
  display: flex;
  justify-content: center;
  text-align: center;
  min-width: 370px;
  z-index: 1000;

  &__container {
    background: white;
    border: 3px solid $accent-700;
    box-shadow: #ced5c196 1px 1px 20px 5px;
    padding: $spacing-100;
    border-radius: 10px;
    color: $accent-700;
    width: auto;

    h3 {
      margin-top: $spacing-200;
      color: $accent-700;
    }

    path {
      fill: $accent-700;
    }
  }

  &__close {
    float: right;
  }

  @media screen and (min-width: $breakpoint-lg) {
    right: 0;
    transform: none;
  }
}
