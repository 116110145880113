@import "stylesheets/base";

.app-bar-top {
  $this: &;

  background: $ui-0;
  padding: $spacing-50 0;
  z-index: 1000;
  text-align: center;

  &__title {
    display: none;
    flex-grow: 1;
    margin-left: -#{rem(30)};
    margin-bottom: 0;

    @media screen and (min-width: $breakpoint-lg) {
      margin-left: 0;
      text-align: left;
    }
  }

  &--scrolled {
    border-bottom: $ui-200 1px solid;

    #{$this}__title {
      display: block;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
  }

  &__link,
  &__close {
    margin-left: -$spacing-150;
    z-index: 100;
  }

  &__close {
    align-self: flex-end;
    margin-left: auto;
    margin-right: -$spacing-150;
  }

  &__modal-title {
    margin-bottom: $spacing-300;
  }

  &__modal-button {
    display: block;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
