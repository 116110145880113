@import "stylesheets/base";

.shop-unavailable {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  margin-top: 4rem;
  text-align: center;

  &__illustration {
    width: 100%;
    max-width: 350px;
    margin-bottom: 1rem;
  }

  &__heading {
    margin-inline: auto;
    margin-bottom: 2rem;
    text-align: center;
  }

  &__ctas {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    text-align: center;

    @media screen and (min-width: $breakpoint-sm) {
      flex-direction: row;
      justify-content: center;
      gap: 1rem;

      button {
        width: 16rem;
      }
    }
  }
}
