@import "stylesheets/base";

.travel-vaccinations-section {
  &__container {
    width: 100%;
    max-width: $breakpoint-lg !important;
    margin-inline: auto;
    padding-block: 2rem;
  }

  &__row {
    align-items: center;
    gap: 1rem 0;

    @media screen and (min-width: $breakpoint-md) {
      flex-flow: row-reverse nowrap !important;
    }
  }

  &__image {
    height: 100%;
    margin-inline: auto;
    max-width: 100%;
    max-height: 300px;

    &-col {
      text-align: center;
    }
  }

  &__subheading {
    font-weight: bold;
    line-height: 22px;
    margin-bottom: 1rem;

    @media screen and (min-width: $breakpoint-lg) {
      font-size: 21px;
      line-height: 26px;
    }
  }

  &__content-col {
    h3 {
      font-weight: 600;
    }

    text-align: center;

    @media screen and (min-width: $breakpoint-md) {
      text-align: start;

      h3 {
        font-size: 48px;
        margin-bottom: 1.5rem;
      }
    }
  }

  &__ctas-container {
    display: flex;
    flex-direction: column;
    margin-top: 1.25rem;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: $breakpoint-md) {
      flex-direction: row;
      justify-content: flex-start;
    }

    @media screen and (min-width: $breakpoint-lg) {
      margin-top: 2.5rem;
      justify-content: flex-start;
    }

    a,
    button {
      width: 100%;
      flex: 1;
      margin: 0;
      padding-left: $spacing-200;
      padding-right: $spacing-200;
      font-size: $label-md-font-size;

      @media screen and (min-width: $breakpoint-md) {
        width: auto;
        max-width: 225px;
      }
    }

    a + a,
    a + button,
    button + a,
    button + button {
      margin-top: 1.25rem;

      @media screen and (min-width: $breakpoint-md) {
        margin: 0 0 0 1.5rem;
      }
    }
  }
}
