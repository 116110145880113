@import "stylesheets/base";

.steps-list {
  display: inline-block;
  font-family: $font-heading;
  text-align: left;
  border: 1px solid $ui-200;
  border-radius: 12px;
  font-size: #{rem(19)};
  padding: $spacing-250 $spacing-300;
  margin: $spacing-250 $spacing-300;

  counter-reset: thislist;

  li {
    position: relative;
    padding-left: #{rem(40)};
    counter-increment: thislist;

    &::before {
      content: counters(thislist, ".") " ";
      display: inline-block;
      position: absolute;
      top: #{rem(1)};
      left: 0;
      height: #{rem(28)};
      width: #{rem(28)};
      font-size: #{rem(17)};
      line-height: #{rem(22)};
      text-align: center;
      color: $accent-500;
    }

    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: -#{rem(2)};
      left: 0;
      z-index: -1;
      height: #{rem(28)};
      width: #{rem(28)};
      border: $accent-500 2px solid;
      border-radius: 50%;
    }
  }

  &--accent li {
    &::before {
      color: $accent-500;
    }

    &::after {
      border-color: $accent-500;
    }
  }

  &--ui li {
    &::before {
      color: $ui-800;
    }

    &::after {
      border-color: $ui-800;
    }
  }

  li:not(:last-child) {
    margin-bottom: $spacing-250;
  }

  &--borderless {
    display: block;
    border: 0;
    padding: 0;
    margin-left: 0;
  }
}
