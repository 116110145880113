@import "src/stylesheets/base";

.cookies {
  &__title {
    margin-bottom: $spacing-250;
  }

  &__article {
    margin-bottom: $spacing-300;
  }

  &__article-list {
    list-style: disc;
    padding: $spacing-100;
    margin-left: $spacing-400;
  }

  &__actions {
    padding: $spacing-400;
    display: flex;
    justify-content: flex-end;
  }
}
