@import "stylesheets/base";

.form-progress-bar {
  display: flex;
  font-family: $font-heading;
  margin: 0 0 $spacing-150;

  &__indicator {
    position: relative;
    z-index: 1;
    display: inline-block;
    text-align: center;
    height: #{rem(28)};
    width: #{rem(28)};
    color: $ui-1000;
    font-size: #{rem(17)};
    line-height: #{rem(22)};
    margin-right: $spacing-300;

    &::before {
      content: "";
      display: block;
      z-index: -1;
      position: absolute;
      top: -#{rem(4)};
      left: 0;
      height: #{rem(28)};
      width: #{rem(28)};
      border: $ui-200 2px solid;
      border-radius: 50%;
    }

    &:not(:last-child)::after {
      content: "";
      display: block;
      position: absolute;
      left: $spacing-400;
      top: #{rem(10)};
      width: $spacing-200;
      height: 2px;
      background: $ui-200;
    }

    &--active {
      color: #fff;

      &::before {
        background: $ui-1000;
        border-color: $ui-1000;
      }
    }

    &--done::before {
      background: $ui-200;
    }
  }

  &--accent {
    .form-progress-bar__indicator--active::before {
      background: $accent-500;
      border-color: $accent-500;
    }
  }
}
