@import "stylesheets/base";

.account-landing {
  padding: $spacing-250 0;

  &__title {
    margin-bottom: $spacing-200;
  }

  &__card {
    margin-bottom: $spacing-100;
  }

  &__card--with-icon {
    margin-bottom: $spacing-100;

    span:first-child svg path {
      fill: $brand;
    }
  }

  &__dependants-title {
    margin-top: $spacing-400;
    color: $ui-600;
  }

  &__actions {
    margin: $spacing-400 $spacing-200;
    padding-top: $spacing-400;
    box-shadow: 0 -24px 3px -24px $ui-200;

    &__logout {
      width: 100%;
    }
  }

  &__modal-body {
    margin-bottom: $spacing-300;
  }

  &__modal-button {
    width: 100%;
    margin: 0 0 $spacing-200 0;
  }
}
