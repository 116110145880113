@import "stylesheets/base";

.prescription-completed {
  padding: 80px 0;

  &__content {
    text-align: center;
    margin-bottom: $spacing-300;
  }

  &__image {
    margin-bottom: $spacing-300;
  }

  &__list {
    margin-bottom: $spacing-300;
  }

  &__button {
    margin-bottom: $spacing-300;
    width: 100%;
  }

  &__info {
    color: $ui-500;
    text-align: center;
  }
}
