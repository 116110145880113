@import "stylesheets/base";

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-error {
  border-radius: $spacing-100;
  background-color: $ui-100;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: #{rem(6)};
}
