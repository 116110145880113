@import "stylesheets/base";

.prescription-list-item {
  position: relative;
  color: $ui-1000;
  text-decoration: none;
  display: block;
  z-index: 997;
  padding: $spacing-200;

  &__title {
    padding-right: $spacing-400;
  }

  &__link {
    display: block;
    text-decoration: none;
    margin-bottom: $spacing-200;

    &:focus {
      outline: 0;
    }
  }

  &__bottom-section {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @media screen and (min-width: $breakpoint-md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__info-container {
    display: block;
    padding: 0 $spacing-200;
    margin: 0;
    font-weight: 500;
    pointer-events: none;
  }

  &__info-icon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &__arrow {
    position: absolute;
    top: $spacing-200;
    right: $spacing-100;

    svg {
      path {
        fill: $ui-1000;
      }
    }
  }

  &:focus {
    outline: none;
    border-color: $accent-600;
    box-shadow: inset 0 0 0 2px $accent-600, 0 0 0 2px $accent-600;
  }

  &__indicator {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: solid 1px $ui-200;
    border-radius: $spacing-150;
    transition: all 0.5s;
  }

  &__button {
    margin: 0;
  }

  &__indicator:hover,
  &__button:hover ~ &__indicator,
  &__link:hover ~ &__indicator {
    background: $ui-100;
  }

  &__button:focus ~ &__indicator,
  &__link:focus ~ &__indicator {
    outline: 0;
    background: $ui-100;
    border-color: $accent-600;
    box-shadow: 0 0 0 2px $accent-600, inset 0 0 0 1px $accent-600;
  }
}
