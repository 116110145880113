@import "stylesheets/base";

.condition-group-page {
  &__container {
    position: relative;
  }

  &__product-list-overrides {
    @media screen and (min-width: $breakpoint-lg) {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    @media screen and (min-width: $breakpoint-xl) {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    &__condition-section-overrides {
      margin-top: 4rem;
    }
  }
}
