@import "stylesheets/base";

.menu-items {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &__shop-button-container {
    margin: 0;
    position: relative;
  }

  button {
    margin: 0;
    height: 3rem;
  }

  &__dropdown {
    display: flex;
    flex-direction: column;
    position: absolute;
    gap: 0.5rem;
    padding: 0.5rem;
    box-shadow: 0 0 0 1px $accent-700 inset;
    background-color: $ui-0;
    z-index: 2000;
    border-radius: 0.5rem;

    button {
      margin: 0;
    }

    &--condition-group {
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      min-width: 100%;
      margin-top: 1rem;
      align-items: stretch;

      button {
        text-align: left;
      }

      span {
        font-size: 17px;
      }
    }

    &--condition {
      left: calc(100% + 0.5rem);
      width: max-content;
    }
  }

  &__shop-button {
    padding-left: 12px;
  }

  &--active {
    span {
      color: $accent-500;
      text-decoration: underline;

      svg {
        path {
          fill: $accent-500;
        }
      }
    }
  }
}
