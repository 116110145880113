@import "stylesheets/base";

.general-health-form {
  max-width: $breakpoint-md !important;
  width: 100% !important;

  &__progress-container {
    display: flex;
    justify-content: center;

    @media screen and (min-width: $breakpoint-sm) {
      margin-block: 1rem;
    }

    @media screen and (min-width: $breakpoint-md) {
      margin-block: 2rem;
    }
  }

  &__product-image {
    max-width: 112px;
    max-height: 112px;
  }

  &__title {
    margin-bottom: 2rem;
  }

  &__intro-pt-2 {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  &__product-info-container {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  &__autocomplete-overrides {
    margin-bottom: 1rem;
  }

  &__input {
    &--half-width {
      width: 100%;

      @media screen and (min-width: $breakpoint-md) {
        width: 50%;
      }

      label,
      input,
      div {
        flex-basis: 50%;
      }
    }

    &__textarea {
      min-height: 150px;
    }
  }

  &__change-gp-button {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: -1.5rem;
  }

  &__select-gp-list {
    margin-inline: 0 !important;
  }

  &__date-fields,
  &__height-fields,
  &__weight-fields {
    display: flex;
    gap: 1rem;
  }

  &__secondary-label {
    font-size: $paragraph-md-font-size !important; /* stylelint-disable-line declaration-no-important */
    font-weight: 500 !important; /* stylelint-disable-line declaration-no-important */
    color: $ui-500;
  }

  &__list {
    padding-left: 1.5rem;
    margin-bottom: 0.75rem;

    &-item {
      list-style: disc;
    }
  }

  &__button-container {
    display: flex;
    flex-direction: column-reverse;

    @media screen and(min-width: $breakpoint-md) {
      flex-direction: row;
      justify-content: flex-end;
      gap: 1rem;
    }
  }

  &__button {
    min-width: 12rem;
  }
}

.utility-styles {
  &__mb-0 {
    margin-bottom: 0;
  }

  &__mb-sm {
    margin-bottom: 0.2rem;
  }

  &__mb-md {
    margin-bottom: 2rem;
  }
}
