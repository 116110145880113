@import "stylesheets/base";

.advice-section {
  background-color: $accent-700;
  padding: 2.25rem 0 !important;
  margin: 0;

  h2,
  h6 {
    color: white;
    margin: 0;
  }

  h2 {
    font-size: 29px;
    line-height: 38px;
    letter-spacing: -0.5px;
    font-weight: 600;
    padding-bottom: 1rem;
  }

  h6 {
    padding-bottom: 1.5rem;
  }

  @media screen and (min-width: $breakpoint-xl) {
    padding-left: 0;
  }

  @media screen and (min-width: $breakpoint-lg) {
    padding: 4.75rem 4rem 4.5rem !important;

    h2 {
      font-size: 64px;
      line-height: 64px;
      letter-spacing: -0.6px;
    }

    h6 {
      font-size: 29px;
      line-height: 38px;
      letter-spacing: -0.5px;
      padding-bottom: 2rem;
    }
  }

  &__row {
    min-width: 100%;
    margin: 0 auto;
    padding: 0 4rem !important;

    @media screen and (min-width: $breakpoint-xl) {
      min-width: $breakpoint-xl;
      max-width: $breakpoint-xl;
    }
  }

  &__col {
    padding: 0 !important;
    max-width: 1024px !important;
  }

  &__cta-overrides {
    width: 100%;
    margin-bottom: 0;

    @media screen and (min-width: $breakpoint-md) {
      max-width: 350px;
    }
  }
}
