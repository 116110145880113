@import "stylesheets/base";

.order-summary {
  width: 100%;

  &__product-list-headings {
    display: flex;
    margin-top: 0.75rem;
    margin-bottom: $spacing-200;

    &__col1 {
      flex: 2;
    }

    &__col2 {
      flex: 1;
      text-align: center;
    }

    @media screen and(min-width: $breakpoint-md) {
      &__col3 {
        max-width: 150px;
        min-width: 110px;
        margin-left: 1rem;
      }
    }
  }

  &__product-list-headings,
  &__product-list-item,
  &__subtotal-line {
    margin-bottom: $spacing-200;
  }

  &__product-list {
    list-style: none;
    margin-bottom: $spacing-300;
  }

  &__subtotal-line {
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacing-200;

    @media screen and(min-width: $breakpoint-md) {
      &__name {
        flex: 3;

        &--short {
          flex: 2;
        }
      }

      &__value {
        max-width: 150px;
        min-width: 110px;
        margin-left: 1rem;
      }
    }
  }
}
