@import "stylesheets/base";

.basket-icon {
  position: relative;

  &__icon-button {
    min-width: 3.5rem;
    color: black;

    * {
      margin-inline: auto;
    }
  }

  &__qty {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10%;
    right: 10%;
    width: 1.2rem;
    height: 1.2rem;
    background: $accent-500;
    color: $ui-0;
    border-radius: 50%;
    font-size: $label-xs-font-size;
    font-weight: $label-xs-font-weight;

    @media screen and (min-width: $breakpoint-md) {
      right: 20%;
    }
  }
}
