@import "stylesheets/base";

.app-bar-bottom {
  $this: &;
  padding: $spacing-150 0 $spacing-50;
  margin: $spacing-500 $spacing-100 $spacing-300;
  z-index: 998;

  @media screen and (min-width: $breakpoint-md) {
    padding: $spacing-200 0 $spacing-100;

    &__inner {
      display: flex;
      flex-direction: row-reverse;

      &--align-center {
        justify-content: center;
      }
    }
  }

  &--fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: $ui-0;
    box-shadow: 0 -1px 0 $ui-200;
    margin: 0;
    padding: $spacing-150 0 $spacing-50;

    @media screen and (min-width: $breakpoint-md) {
      padding: $spacing-200 0 $spacing-100;
    }
  }

  &:not(#{$this}--fixed) {
    #{$this}__container {
      padding: 0;
    }
  }

  &__stack {
    display: flex;
    flex-direction: column;

    [class*="_btn_"] {
      margin: 0 0 $spacing-100 0;
    }
  }
}
