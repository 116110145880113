@import "stylesheets/base";

.feature {
  &__container {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1.125rem;
    text-align: left;

    & + & {
      margin-top: 1rem;
    }

    h5,
    p {
      margin: 0;
    }
  }

  &__icon {
    flex: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__text-col {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
}

.features-section {
  width: 100%;
  margin: 0 auto;
  padding: 4rem 1rem;
  max-width: 650px;

  @media screen and (min-width: $breakpoint-lg) {
    padding: 4rem 0;
  }

  &__col {
    text-align: center;
  }

  &__button-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1.5rem auto 0;

    @media screen and (min-width: $breakpoint-lg) {
      flex-direction: row;
      margin-top: 2rem;
    }
  }

  &__cta-overrides {
    width: 100%;
    margin: 0;
  }
}
