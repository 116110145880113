@import "stylesheets/base";

.input__pharmacy-group {
  display: flex;
  flex-direction: column;
  margin-bottom: $spacing-300;

  .input__pharmacy-circle {
    position: absolute;
    display: block;
    top: $spacing-200;
    left: $spacing-200;
    margin-right: $spacing-150;
    height: $radio-md-diameter;
    width: $radio-md-diameter;

    &::before,
    &::after {
      position: absolute;
      content: "";
      border-radius: 50%;
      border-style: solid;
      border-width: $radio-border-width;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &::before {
      height: $radio-md-diameter;
      width: $radio-md-diameter;
      border-color: $ui-500;
    }

    &::after {
      height: $radio-md-spacing;
      width: $radio-md-spacing;
      border-color: transparent;
      background: transparent;
      left: $radio-outline-offset;
    }
  }

  &--vertical {
    display: flex;
    flex-direction: column;
  }

  .input__pharmacy-label {
    display: flex;
    position: relative;
    align-items: center;
    font-weight: normal;
    color: $ui-1000;
    margin-bottom: $spacing-100;
    border-radius: $spacing-100;
    padding: $spacing-200;
    padding-left: calc(#{rem(28)} + 4px);

    .input__faux-background {
      display: block;
      position: absolute;
      width: 100%;
      top: -2px;
      right: 2px;
      bottom: -2px;
      left: -2px;
      z-index: -1;
      border: $ui-200 1px solid;
      border-radius: $spacing-100;
    }

    &:hover {
      cursor: pointer;

      .input__faux-background {
        background: $ui-100;
      }
    }

    &:focus {
      .input__faux-background {
        border: $accent-600 2px solid;
      }
    }
  }

  &.disabled {
    opacity: 0.24;
  }

  .input__pharmacy-text {
    display: flex;
    flex-direction: column;
    padding-left: $spacing-250;
  }

  .input__pharmacy-name {
    margin-bottom: 0;
  }

  .input__delivery-options {
    order: 3;
  }

  .input__delivery-option {
    display: flex;
    font-family: $font-body;
    font-weight: 500;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .input__address {
    font-family: $font-body;
    margin-bottom: $spacing-150;
  }

  .input__check {
    margin-right: $spacing-150;

    path {
      fill: $brand;
    }
  }

  .input__clear {
    margin-right: $spacing-150;

    path {
      fill: $negative-500;
    }
  }

  .input__pharmacy {
    opacity: 0;
    position: absolute;
    width: 0;
    margin: 0;
    height: 0;

    &:checked ~ .input__pharmacy-circle {
      &::before {
        background-color: $ui-0;
        border-width: $radio-md-border-width;
        border-color: $accent-500;
        border-style: solid;
      }
    }

    /* stylelint-disable-next-line */
    &:checked ~ .input__pharmacy-text .input__pharmacy-name {
      color: $accent-700;
    }

    &:checked ~ .input__faux-background {
      background-color: $accent-100;
      border: $accent-600 1px solid;
    }

    &:disabled ~ .input__pharmacy-circle {
      pointer-events: none;
    }

    &:focus ~ .input__faux-background {
      border: 0;
      box-shadow: 0 0 0 2px $accent-600, inset 0 0 0 1px $accent-600;
    }
  }
}
