.pharmacy-contact-card {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-height: 250px;

  padding: 1.5rem 1.25rem;
  border-radius: 0.75rem;

  background: #ffffff;
  filter: drop-shadow(0 4px 16px rgba(219, 224, 229, 0.5));

  &__details {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    gap: 2rem;

    height: max-content;

    &__address {
      flex: 1;
    }
  }

  &__icons {
    flex-shrink: 0;
    margin-right: 0.5rem;

    * {
      fill: black;
    }
  }
}
