@import "stylesheets/base";

.pmed-tile {
  padding: $spacing-200;
  background-color: $ui-0;
  box-shadow: inset 0 0 0 1px $ui-200;
  border-radius: $spacing-150;
  margin-bottom: $spacing-150;

  &:last-child {
    margin-bottom: 0;
  }

  &__image {
    width: $spacing-700;
    margin-right: $spacing-200;
    min-width: $spacing-700;
  }

  &__header {
    margin-bottom: $spacing-200;
    display: flex;
    align-items: center;
  }

  &__controls {
    display: flex;
    align-items: center;

    button {
      margin-bottom: 0;
      font-size: #{rem(17)};
    }
  }

  &__delete-button {
    margin-right: $spacing-200;
  }

  &__form-button {
    width: 100%;
  }

  &__complete {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $spacing-500;
    width: 100%;
    box-shadow: 0 0 0 1px $ui-200;
    border-radius: $spacing-100;

    &-title {
      margin-bottom: 0;
    }

    &-tick {
      margin-right: $spacing-100;

      path {
        fill: $brand;
      }
    }
  }
}
