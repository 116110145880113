@import "stylesheets/base";

.prescription-next-steps {
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
  margin-top: 1rem;

  @media screen and (min-width: $breakpoint-sm) {
    margin-top: 2rem;
  }

  @media screen and (min-width: $breakpoint-lg) {
    margin-top: 4rem;
  }

  &__illustration {
    width: 100%;
    height: 16rem;
    margin-bottom: 1rem;
  }

  &__heading {
    margin-bottom: 2rem;
  }

  &__ctas {
    display: flex;
    justify-content: center;

    button {
      margin-inline: auto;
    }
  }
}
