@import "stylesheets/base";

.banner-section {
  background-color: $accent-500;

  @media screen and (min-width: $breakpoint-lg) {
    margin-block: 4rem;

    &--condensed {
      margin-block: 1.75rem;
    }

    &--no-image,
    &--no-overflow {
      margin-block: 0;
    }
  }

  &__container {
    width: 100% !important;
    max-width: none;

    @media screen and (min-width: $breakpoint-xl) {
      max-width: $breakpoint-xl !important;
    }
  }

  &__text-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1.875rem 1.5rem !important;

    h2,
    h3,
    p {
      color: white;
    }

    h2,
    h3 {
      font-weight: 600;
    }

    &--no-image {
      gap: 1rem;
      padding: 1.875rem 1rem !important;
    }

    @media screen and (min-width: $breakpoint-md) {
      padding: 1.875rem 2.5rem !important;

      h2 {
        font-size: 3rem;
        line-height: 3rem;
      }

      h3 {
        font-size: 1.5rem;
        line-height: 2.3rem;
      }
    }

    @media screen and (min-width: $breakpoint-lg) {
      padding: 1.875rem 1rem 1.875rem 4rem !important;
      min-height: 405px !important;
      gap: 1rem;

      &--condensed {
        min-height: 0 !important;
        max-height: fit-content !important;
        gap: 2rem;
      }

      &--no-image {
        padding: 1.875rem 2.5rem !important;
        gap: 2rem;
      }

      h2 {
        font-size: 4rem;
        line-height: 4rem;
      }

      h3 {
        font-size: 1.875rem;
        line-height: 2.375rem;
        padding-right: 3rem;
      }

      p {
        padding-right: 3rem;
      }
    }

    @media screen and (min-width: $breakpoint-xl) {
      min-height: 495px !important;

      &--condensed {
        min-height: 0 !important;
      }

      &--no-image {
        padding-inline: 2rem !important;
      }
    }
  }

  &__ctas-container {
    margin-top: 1.5rem;
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;

    @media screen and (min-width: $breakpoint-md) {
      margin-top: 1rem;
      flex-flow: row nowrap;
    }

    @media screen and (min-width: $breakpoint-lg) {
      padding-right: 3rem;
    }
  }

  &__cta-overrides {
    margin: 0;
    flex-basis: 100%;

    @media screen and (min-width: $breakpoint-md) {
      flex-basis: 50%;
    }

    &--ctaTwo {
      border: 2px solid white;
    }
  }

  &__image-column {
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    @media screen and (min-width: $breakpoint-lg) {
      margin-block: -4rem;

      &--condensed {
        margin-block: -1.75rem;

        &--no-text {
          margin-block: -3rem;
        }
      }

      &--no-overflow {
        margin-block: 0;
      }
    }

    img {
      min-width: 100%;
      height: 100%;
      max-height: 400px;
      object-fit: cover;
      object-position: right center;

      @media screen and (min-width: $breakpoint-lg) {
        max-height: unset;
      }
    }

    &--condensed {
      img {
        max-height: 400px;
      }

      &--no-text {
        max-height: 350px;
      }
    }
  }
}
