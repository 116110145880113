@import "stylesheets/base";

.post-code-search {
  &__form {
    display: flex;
    position: relative;
  }

  &__input {
    flex-grow: 2;
    margin-bottom: 12px;

    [role="alert"] {
      @include sr-only;
    }
  }

  &__label {
    @include sr-only;
  }

  &__search-button {
    margin-left: $spacing-150;
  }
}
