@import "stylesheets/base";

.condition-tagline-section {
  position: relative;
  width: 100% !important;
  max-width: $breakpoint-xl !important;
  margin-block: 0 4.5rem;
  padding: 0 !important;

  &__read-more {
    position: absolute;
    padding-left: 0;
    text-align: right;
    background: transparent;
    box-shadow: unset;

    &:hover {
      background: unset;
      box-shadow: unset;
    }

    &:focus {
      box-shadow: unset;
    }
  }

  div {
    padding-left: 0;
  }

  @media screen and (min-width: $breakpoint-lg) {
    margin-block: 1rem 4.5rem;
  }
}
