@import "stylesheets/base";

.help-centre-landing {
  &__title {
    padding-top: $spacing-200;
    margin-bottom: $spacing-200;
  }

  &__subtitle {
    color: $ui-600;
    margin-bottom: $spacing-150;
  }

  &__card {
    margin-bottom: $spacing-150;
  }

  &__card-list {
    margin-bottom: $spacing-300;
  }

  &__card--with-icon {
    margin-bottom: $spacing-150;

    span:first-child svg path {
      fill: $ui-1000;
    }
  }

  &__about {
    margin-bottom: $spacing-200;
  }
}
