@import "stylesheets/base";

.prescription-exemption {
  $this: &;
  margin-inline: auto;

  @media screen and (min-width: $breakpoint-md) {
    max-width: 450px;
  }

  &__title {
    margin-bottom: $spacing-200;
  }

  &__progress-container {
    display: flex;
    justify-content: center;

    @media screen and (min-width: $breakpoint-sm) {
      margin-block: 1rem;
    }

    @media screen and (min-width: $breakpoint-md) {
      margin-block: 2rem;
    }
  }

  &__button {
    width: 100%;
    margin-bottom: $spacing-200;

    @media screen and (min-width: $breakpoint-md) {
      margin-bottom: 0;
    }
  }

  &__button-set {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $breakpoint-md) {
      flex-direction: row;

      #{$this}__button:first-child {
        margin-right: $spacing-300;
      }
    }
  }

  &__card {
    margin: $spacing-200 0 $spacing-300;
  }
}
