@import "stylesheets/base";

.vaccination-info {
  &__container {
    display: flex;
    padding-top: 2rem;
    gap: 5rem;
    flex-direction: row;

    a {
      color: inherit;
    }
  }

  &__cta-one {
    padding-bottom: 2rem;
  }
}
