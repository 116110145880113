@import "stylesheets/base";

.landing-page {
  &__section-overrides {
    &__pill-link-section {
      margin-bottom: 0;
      padding-bottom: 0;

      &--padded {
        padding-bottom: 3rem;
      }
    }

    &__here-to-help-section {
      margin-bottom: -4rem;
    }
  }
}
