@import "stylesheets/base";

.info-card {
  &__container {
    max-width: 300px;
    border-radius: 0.75rem;
    background-color: white;
    filter: drop-shadow(0 4px 16px rgba(219, 224, 229, 0.5));
  }

  &__img-container {
    position: relative;
    border-radius: 0.75rem 0.75rem 0 0;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__step-number {
    background-color: $accent-500;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;

    h1 {
      color: white;
      margin: 0;
    }
  }

  &__text-container {
    padding: 1rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    * {
      color: $ui-800;
    }
  }
}
