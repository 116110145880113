@import "stylesheets/base";

.product-image {
  width: #{rem(112)};

  &__image {
    width: 100%;
  }

  &__error {
    background-color: $ui-100;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6px;
    aspect-ratio: 1/1;
    width: 100%;
  }
}
