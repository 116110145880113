/* use format $breakpoint-{breakpoint-name} to aid autocomplete. */
$breakpoint-xs: 0; //only used in bs3
$breakpoint-sm: 768px;
$breakpoint-md: 1024px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1440px;

//grid gutters
$gutter-width-xs: 16px;
$gutter-width-sm: 16px;
$gutter-width-md: 24px;
$gutter-width-lg: 32px;
$gutter-width-xl: 32px;

//only used in Bs4
$container-max-width-sm: $breakpoint-sm;
$container-max-width-md: $breakpoint-md;
$container-max-width-lg: $breakpoint-lg;
$container-max-width-xl: $breakpoint-xl;

/* stylelint-disable-next-line selector-pseudo-class-no-unknown */
:export {
  breakpointxs: $breakpoint-xs;
}
