@import "stylesheets/base";

.info-panel {
  position: relative;
  display: block;
  border: 1px solid $ui-200;
  border-radius: $spacing-150;
  padding: $spacing-200 $spacing-200 $spacing-200 $spacing-600;

  @media screen and (min-width: $breakpoint-md) {
    margin: $spacing-300 auto;
  }

  &__title {
    margin: 1px 0 $spacing-200;
  }

  &__text p {
    margin-bottom: $spacing-50;
  }

  &__info-icon {
    position: absolute;
    margin-left: -1px;
    left: $spacing-200;
    top: $spacing-200;
  }

  &__link {
    display: block;
    padding: $spacing-50 0;
    font-family: $font-heading;
    font-size: #{rem(17)};
    color: $accent-600;
    font-weight: 600;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
