@import "stylesheets/base";

.toast {
  position: relative;
  color: $ui-0;
  padding: $spacing-150;
  display: block;
  width: 100%;

  &__title,
  &__descripton {
    color: $ui-0;
  }

  &__buttons {
    width: 100%;
    margin-top: $spacing-150;
    text-align: right;
  }

  &__button {
    width: 100%;

    @media screen and (min-width: $breakpoint-lg) {
      width: auto;
    }
  }

  &__icon {
    display: inline-block;
    padding-right: $spacing-400;
    height: 24px;
    width: 24px;
  }
}
