@import "stylesheets/base";

.booking-page {
  &__widget__container {
    width: 100%;
    max-width: 1250px;
    margin: 1.5rem auto 0;

    @media screen and (min-width: $breakpoint-md) {
      padding-inline: 16px;
    }
  }
}
