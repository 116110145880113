$ui-0: #ffffff;
$ui-100: #f5f6f8;
$ui-200: #dbe0e5;
$ui-300: #aab6c1;
$ui-400: #8794a0;
$ui-500: #63717e;
$ui-600: #4d5d6c;
$ui-700: #4d5d6c;
$ui-800: #213143;
$ui-900: #0b1a2b;
$ui-1000: #000a13;

$accent-100: #f5faf9;
$accent-200: #cde7e1;
$accent-300: #7cc0b0;
$accent-400: #2c997f;
$accent-500: #048667;
$accent-600: #037157;
$accent-700: #035b46;
$accent-800: #024636;
$accent-900: #013025;

/* get values from sean */
$positive-100: #f5faf8;
$positive-200: #cde7da;
$positive-300: #7dc1a0;
$positive-400: #2d9b65;
$positive-500: #058848;
$positive-600: #04723c;
$positive-700: #035c31;
$positive-800: #034725;
$positive-900: #02311a;

/* get values from sean */
$warning-100: #fffcf7;
$warning-200: #fff1d5;
$warning-300: #ffdb92;
$warning-400: #ffc44f;
$warning-500: #ffb92e;
$warning-600: #d69b27;
$warning-700: #ad7e1f;
$warning-800: #856018;
$warning-900: #5c4311;

$negative-100: #fdf7f7;
$negative-200: #f7d6d6;
$negative-300: #ea9493;
$negative-400: #dd5351;
$negative-500: #d63230;
$negative-600: #b42a28;
$negative-700: #922221;
$negative-800: #6f1a19;
$negative-900: #4d1211;

$brand: #00a676;
$footer-background: #144f3e;
$footer-background-darker: #293b34;
$footer-accent: #1c644e;
