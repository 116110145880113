@import "stylesheets/base";

.drop-down {
  width: 100%;
  display: inline-block;
  $root: &;
  position: relative;
  margin-bottom: $spacing-300;

  &__text-label {
    font-size: $label-lg-font-size;
    font-weight: $label-lg-font-weight;
    letter-spacing: $label-lg-letter-spacing;
    line-height: $label-lg-line-height;
    margin-bottom: 10px;
    display: block;
  }

  &__text-group--negative {
    .drop-down__text-validation,
    .drop-down__text-label {
      color: $negative-500;
    }
  }

  &__select {
    position: relative;
    appearance: none;
    border-radius: $spacing-100;
    border: none;
    padding: $spacing-100 $spacing-450 $spacing-100 $spacing-200;
    font-size: $paragraph-lg-font-size;
    font-weight: $paragraph-lg-font-weight;
    letter-spacing: $paragraph-lg-letter-spacing;
    line-height: $paragraph-lg-line-height;
    width: 100%;
    //font-family: $font-heading;
    cursor: inherit;
    color: $ui-1000;
    background: transparent url("../../../assets/icons/lawsat/dropdown.svg")
      no-repeat;
    background-position: right $spacing-150 center;
    margin-bottom: $spacing-150;

    &:focus {
      outline: 0;
      box-shadow: 0 0 0 2px $accent-600, inset 0 0 0 1px $accent-600;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &--accent {
    #{$root}__select {
      background-color: $ui-200;

      &:hover {
        box-shadow: 0 0 0 1px $ui-300;
        transition: all 0.25s ease-in-out;
      }
    }
  }

  &--negative {
    #{$root}__select {
      background: $negative-200
        url("../../assets/icons/lawsat/dropdown-negative.svg") no-repeat;
      background-position: right $spacing-150 center;

      &:hover {
        box-shadow: 0 0 0 1px $negative-300;
        transition: all 0.25s ease-in-out;
      }

      &:focus {
        box-shadow: 0 0 0 2px $negative-500;
      }
    }
  }
}
