/* stylelint-disable max-nesting-depth */
@import "stylesheets/base";

.price-section {
  width: 100%;
  padding: 2rem 1.5rem 4rem;
  max-width: $breakpoint-xl;
  margin-inline: auto;

  &__heading {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  &__content {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    margin-top: 2rem;
    margin-inline: auto;

    @media screen and (min-width: $breakpoint-md) {
      padding-inline: 2rem;
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (min-width: $breakpoint-lg) {
      padding-inline: 3rem;
    }

    @media screen and (min-width: $breakpoint-xl) {
      padding-inline: 0;
      row-gap: 0;
      grid-template-columns: 1fr 1fr 1fr;
      max-width: 1440px;
    }

    &__group {
      height: fit-content;
      padding: 2rem;
      border-radius: 12px;
      background: white;
      box-shadow: 0 4px 16px 0 rgba(219, 224, 229, 0.5);

      &--0 {
        @media screen and (min-width: $breakpoint-xl) {
          grid-row: 1 / 3;
          grid-column: 1;
        }
      }

      &--1 {
        @media screen and (min-width: $breakpoint-xl) {
          grid-row: 1;
          grid-column: 2;
        }
      }

      &--2 {
        @media screen and (min-width: $breakpoint-xl) {
          margin-top: -5rem;
          grid-row: 2;
          grid-column: 2;
        }
      }

      &--3 {
        grid-row: 3;

        @media screen and (min-width: $breakpoint-md) {
          grid-row: 1;
          grid-column: 2;
        }

        @media screen and (min-width: $breakpoint-xl) {
          grid-row: 1 / 3;
          grid-column: 3;
        }
      }

      &__heading {
        color: $accent-700;
      }

      &__items {
        list-style: disc;
        list-style-type: disc;
        list-style-position: outside;
        padding-left: 1.25rem;

        &__item {
          display: list-item;

          &__container {
            display: flex;
            flex-flow: row nowrap;
            gap: 1rem;
            align-items: center;

            span:nth-child(1) {
              flex: 1;
              word-break: normal;
            }

            span:nth-child(2) {
              flex: 0;
            }
          }
        }

        &__container {
          margin-top: 1rem;
        }
      }
    }
  }

  &__cta {
    width: 100%;
    margin-top: 2rem;
    margin-inline: auto;
    text-align: center;

    a {
      margin: 0;
      width: 100%;

      @media screen and (min-width: $breakpoint-md) {
        width: auto;
      }
    }
  }
}
