@import "stylesheets/base";

.account-completed {
  padding: 80px 0;

  &__content {
    text-align: center;
    margin-bottom: $spacing-300;
  }

  &__image {
    margin-bottom: $spacing-300;
  }

  &__list {
    margin-bottom: $spacing-300;
  }

  &__info {
    color: $ui-500;
    text-align: center;
  }

  &__contextual-button {
    margin-block: 2rem 4rem;
  }
}
