@import "stylesheets/base";

$sizes: (md $radio-md-diameter $radio-md-spacing $radio-md-border-width 1rem);

$variants: (
  ui $ui-500 $ui-1000 $ui-0 $ui-200 $ui-0 $ui-800 $ui-300 $ui-800 $accent-600
);

.input__toggle-container {
  display: flex;

  .input__toggle:checked ~ .input__toggle-circle {
    &::before {
      border-width: #{$radio-md-border-width};
    }
  }

  .input_toggle-indicator {
    position: relative;

    &::before {
      border-color: $ui-500;
    }

    &:active::before {
      background-color: $ui-300;
    }
  }

  .input__toggle-indicator {
    position: relative;
    display: inline-block;
    margin-right: $spacing-150;
    height: $spacing-300;
    width: $spacing-500;
    z-index: 2;

    &::before {
      height: $spacing-300;
      width: $spacing-500;
      border-radius: $spacing-150;
      background: $ui-500;
    }

    &::before,
    &::after {
      position: absolute;
      content: "";
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &::after {
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      border: transparent;
      background: $ui-0;
      left: $spacing-50;
      transform: translateY(-50%);
      transition: all 0.25s linear;
    }
  }

  .input__faux-focus {
    display: none;
    position: absolute;
    left: -1px;
    height: $spacing-300 + 2px;
    width: $spacing-500 + 2px;
    border-radius: $spacing-150;
    box-shadow: 0 0 0 2px #037157;
    z-index: 0;
  }

  .input__toggle-label {
    margin-right: 0;
    display: flex;
    position: relative;
    align-items: center;
    font-family: $font-body;
    font-weight: normal;
    color: $ui-1000;
    margin-bottom: 0;
    border-radius: $spacing-100;
    font-size: #{rem(16)};
    gap: 0.5rem;

    &:hover {
      cursor: pointer;
    }
  }

  &.disabled {
    opacity: 0.24;
  }

  .input__toggle {
    opacity: 0;
    position: absolute;
    width: 0;
    margin: 0;
    height: 0;

    &:checked ~ .input__toggle-indicator::after {
      left: $spacing-250;
    }

    &:checked ~ .input__toggle-indicator::before {
      background: $accent-500;
    }

    &:disabled ~ .input__toggle-indicator {
      pointer-events: none;
    }

    &:focus ~ .input__faux-focus {
      display: block;
    }
  }
}
