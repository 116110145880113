@import "stylesheets/base";

.card-list {
  $this: &;
  background: #fff;

  &__card {
    position: relative;
    border-top-color: transparent;
    border-bottom-color: transparent;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: $spacing-200;
      right: $spacing-200;
      bottom: -2px;
      height: 1px; /* or 100px */
      border-bottom: 1px solid $ui-200;
    }

    &--has-icons::before {
      left: $spacing-500;
    }

    &:hover {
      border-top-color: $ui-200;
      border-bottom-color: $ui-200;

      &::before {
        border-color: transparent;
      }
    }

    &:focus {
      border-color: $accent-600;

      &::before {
        border-color: transparent;
      }
    }
  }

  & li:first-child &__card {
    border-top-color: $ui-200;
    border-radius: $spacing-150 $spacing-150 0 0;
  }

  & li:last-child &__card {
    border-bottom-color: $ui-200;
    border-radius: 0 0 $spacing-150 $spacing-150;

    &::before {
      content: none;
    }
  }

  & li:first-child &__card:hover {
    border-bottom-color: $ui-200;
  }

  & li:last-child &__card:hover {
    border-bottom-color: $ui-200;
  }

  & li:first-child &__card:focus {
    border-top-color: $accent-600;
  }

  & li:last-child &__card:focus {
    border-bottom-color: $accent-600;
  }
}
