@import "stylesheets/base";

.pricing-page {
  &__spinner {
    padding: 100px 0;
  }

  &__booking-process-section {
    padding-block: 4rem 4rem !important;

    @media screen and (min-width: $breakpoint-lg) {
      padding-bottom: 4.5rem !important;
    }
  }

  &__footer-overrides {
    margin-top: 0;
  }
}

.spacer {
  padding-top: 1.5rem;

  @media screen and (min-width: $breakpoint-lg) {
    padding-top: 4rem;
  }
}
