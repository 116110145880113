@import "stylesheets/base";

.prescription-exemption {
  &__form {
    border-radius: $spacing-150;
    border: 1px solid $ui-200;
    padding: $spacing-150 $spacing-200 $spacing-200 $spacing-200;
  }

  &__progress-container {
    display: flex;
    justify-content: center;

    @media screen and (min-width: $breakpoint-sm) {
      margin-block: 1rem;
    }

    @media screen and (min-width: $breakpoint-md) {
      margin-block: 2rem;
    }
  }

  &__dateFields {
    display: flex;

    label {
      font-family: $font-body;
      font-size: $paragraph-md-font-size;
      font-weight: normal !important; /* stylelint-disable-line declaration-no-important */
      color: $ui-500;
    }
  }

  &__dateInput {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:not(:last-child) {
      margin-right: $spacing-150;
    }
  }

  &__title {
    padding-bottom: $spacing-200;
    margin-bottom: 0;
  }

  &__text-validation {
    display: block;
    color: $negative-500;
    margin-top: -$spacing-100;
    margin-bottom: $spacing-150;
  }

  &__expire-reminder {
    color: $ui-500;
  }
}
