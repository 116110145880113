@import "stylesheets/base";

.delivery-option-card {
  display: flex;
  gap: 1rem;
  padding: 1.5rem 2rem;
  border-radius: 0.75rem;
  box-shadow: 0 4px 16px 0 rgba(219, 224, 229, 0.5);
  border: 3px solid transparent;
  margin-bottom: 1rem;
  min-height: 10rem;

  &:focus-within {
    border: 3px solid $accent-500;
  }

  &--checked {
    border: 3px solid $accent-500;
  }

  &__icon {
    flex-shrink: 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex-grow: 1;
    justify-content: space-between;
  }

  &__info-text {
    line-height: $paragraph-xs-line-height;
    color: $ui-500;
  }

  &__checkbox-container {
    display: flex;
    align-items: center;
  }

  &:hover {
    cursor: pointer;
  }
}

.custom-checkbox {
  position: relative;
  cursor: pointer;
  display: inline-block;

  &__hidden {
    opacity: 0;
  }

  &__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.875rem;
    width: 1.875rem;
    border: 3px solid $accent-500;
    border-radius: 50%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      display: none;
      content: "";
      width: 1.125rem;
      height: 1.125rem;
      background-color: $accent-500;
      border-radius: 50%;
    }
  }

  &__hidden:checked + &__checkmark::after {
    display: block;
  }
}

.dropdown {
  margin-bottom: 0;

  select {
    border-radius: 0.75rem;
    border: 1px solid $ui-300;

    &:focus {
      border: none;
    }
  }
}
