@import "stylesheets/base";

.order-summary-line {
  display: flex;

  &__col1 {
    flex: 2;
  }

  &__col2 {
    text-align: center;
    flex: 1;
  }

  &__col3 {
    margin-left: 1rem;
  }

  @media screen and(min-width: $breakpoint-md) {
    &__col3 {
      max-width: 150px;
      min-width: 110px;
      margin-left: 1rem;
    }
  }

  &__col1,
  &__col2 {
    margin: 0;
  }
}
