@import "stylesheets/base";

.prescription-order-item {
  display: block;
  padding: $spacing-200 0 $spacing-300;
  border-bottom: 1px solid $ui-200;

  &__title {
    margin-bottom: $spacing-150;
  }

  &__foot {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: $spacing-150;
  }

  &__price {
    font-family: $font-heading;
    font-size: #{rem(19)};
    line-height: #{rem(24)};
    letter-spacing: #{rem(-0.25)};
  }
}
