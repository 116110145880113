/* stylelint-disable value-no-vendor-prefix */
/* stylelint-disable property-no-vendor-prefix */
@import "stylesheets/base";

.product-tile {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  height: 100%;
  width: 100%;
  min-height: 275px;
  max-width: 300px;
  padding: 1rem 1rem 1.5rem;
  border-radius: 0.5rem;
  box-shadow: inset 0 0 0 1px $ui-200;
  text-decoration: none;

  &--with-shadow {
    border-radius: #{rem(12)};
    box-shadow: 0 4px 16px 0 rgba(219, 224, 229, 0.5);
  }

  &:hover,
  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 3px $accent-600;
  }

  &__out-of-stock {
    position: absolute;
    align-self: flex-start;
    background-color: $negative-600;
    border-radius: 0.5rem 0;
    padding: 0 1rem;
    top: 0;
    left: 0;

    * {
      color: white;
      margin: 0;
    }
  }

  &__image {
    height: 112px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title {
    flex: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    margin: 0;
    align-self: flex-start;
  }

  &__footer {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-self: stretch;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__price {
    margin: 0;
    font-size: 18px;
  }

  &__chevron {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    width: 44px;
    padding: 0.5rem;
    color: white;
    border-radius: 0.5rem;
    background: $accent-500;
  }
}
