@import "stylesheets/base";

.booking-process-section {
  padding: 0;

  &__heading-col {
    padding-block: 1.5rem 1rem;
    padding-inline: 0 !important;
    margin-inline: auto;
    width: fit-content !important;
    display: flex;
    justify-content: center;
    text-align: center;
  }

  &__cards-col {
    display: grid;
    margin: 0 auto;
    padding: 1rem;
    width: fit-content !important;
    gap: 1rem;

    @media screen and (min-width: $breakpoint-md) {
      grid-template-columns: 300px 300px 300px;
      gap: 1rem;
    }

    @media screen and (min-width: $breakpoint-lg) {
      padding: 0;
      gap: 2rem;
    }

    @media screen and (min-width: $breakpoint-xl) {
      gap: 3rem;
    }
  }

  &__cta__overrides {
    width: 100%;
    margin: 0;

    @media screen and (min-width: $breakpoint-md) {
      grid-column: 2;
    }
  }
}
