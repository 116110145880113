@import "stylesheets/base";

.checkout-order-card {
  border-radius: 0.75rem;
  box-shadow: 0 4px 16px 0 rgba(219, 224, 229, 0.5);
  flex-grow: 1;
  padding: 1rem;

  @media screen and(min-width: $breakpoint-md) {
    padding: 2.5rem 2rem;
  }

  &__title {
    margin-bottom: $spacing-200;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media screen and(min-width: $breakpoint-md) {
      flex-direction: row;
      justify-content: space-between;
      gap: 4rem;
    }
  }

  &__summary-section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @media screen and(min-width: $breakpoint-md) {
      flex-basis: 0;
      max-width: 50%;
    }
  }

  &__delivery-section {
    flex-grow: 1;

    @media screen and(min-width: $breakpoint-md) {
      flex-basis: 0;
      max-width: 50%;
    }
  }

  &__free-delivery-msg,
  &__error-msg {
    line-height: $paragraph-xs-line-height;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    svg {
      flex-shrink: 0;
    }
  }

  &__free-delivery-msg {
    color: $accent-500;

    svg {
      path {
        fill: $accent-500;
      }
    }
  }

  &__error-msg {
    color: $negative-500;
    margin-bottom: 1rem;

    svg {
      path {
        fill: $negative-500;
      }
    }
  }
}
