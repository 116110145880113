@import "stylesheets/base";

.checkout {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media screen and(min-width: $breakpoint-md) {
    flex-basis: 0;
  }

  &__top-heading {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  &__note {
    margin: 0 !important;

    &__label {
      font-size: $title-md-font-size !important;
      line-height: $title-md-line-height !important;
      letter-spacing: $title-md-letter-spacing !important;
    }

    &__info-msg {
      line-height: 1.25rem;
      text-align: right;
    }
  }

  &__footer {
    border-radius: 0.75rem;
    box-shadow: 0 4px 16px 0 rgba(219, 224, 229, 0.5);
    padding: 1.4rem 1rem;

    &__flex {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      @media screen and(min-width: $breakpoint-md) {
        flex-direction: row;
        justify-content: space-between;
        padding: 2.5rem 2rem;
      }
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      @media screen and(min-width: $breakpoint-md) {
        flex-direction: row;
        justify-content: flex-end;
      }
    }

    &__buttons > * {
      min-width: 14rem;
      flex-grow: 1;
      margin: 0;
    }

    &__logos {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;

      @media screen and (min-width: $breakpoint-md) {
        justify-content: flex-end;
      }
    }

    hr {
      display: block;
      border: 1px solid $ui-200;
      margin-top: 1.5rem;
      float: right;
      width: 100%;
    }
  }
}
