@import "stylesheets/base";

.condition-description-section {
  width: 100% !important;
  max-width: $breakpoint-xl !important;
  margin-top: 2rem;

  @media screen and (min-width: $breakpoint-lg) {
    margin-top: 4.5rem;
  }
}
