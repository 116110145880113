@import "stylesheets/base";

.cta-link-card {
  &__container {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    box-shadow: 0 4px 16px 0 rgba(219, 224, 229, 0.5);
    border-radius: 0.75rem;
    padding: 1rem;
    gap: 0.5rem;

    @media screen and (min-width: $breakpoint-sm) {
      gap: 1.5rem;
    }
  }

  &__button {
    flex: 1;
    margin: 0;
    color: $ui-0 !important;
    align-self: center;
  }
}
