@import "stylesheets/base";

.privacy-policy {
  &__title {
    margin-bottom: $spacing-250;
  }

  &__article {
    margin-bottom: $spacing-300;
  }

  &__article-list {
    list-style: disc;
    padding: $spacing-100;
    margin-left: $spacing-400;
  }

  &__article-clause {
    padding: $spacing-100;
    margin-left: $spacing-250;
    list-style: decimal;
  }
}
