@import "stylesheets/base";

.order-detail {
  margin-bottom: $spacing-300;

  &__main-title {
    margin-bottom: $spacing-200;
  }

  &__status-title {
    color: $ui-800;
  }

  &__details-title {
    color: $ui-800;
    margin-bottom: 0;
  }

  &__label {
    color: $ui-600;
    margin-bottom: 0;
  }

  &__cost-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacing-150;
    align-items: baseline;
  }

  &__total-cost-row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 2rem;

    label {
      margin: 0;
    }
  }

  &__total-cost-amount {
    margin-bottom: 0;
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: stretch;
  }

  &__icon-column {
    margin-right: $spacing-150;
    margin-top: $spacing-300;
    width: $spacing-300;
    height: $spacing-300;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    svg path {
      fill: $brand;
    }
  }

  &__order-icon-column {
    margin-right: $spacing-150;
    margin-top: $spacing-150;
    width: $spacing-300;
    height: $spacing-300;
    text-align: center;
    border-radius: 6px;
    background-color: $accent-200;
    display: flex;
    justify-content: center;
    align-items: center;

    svg path {
      fill: $brand;
    }

    &--cancelled {
      background-color: $negative-200;
    }
  }

  &__content-column {
    margin-top: $spacing-150;
    border-top: 1px solid $ui-200;
    padding-top: $spacing-150;
    width: $spacing-400;
    flex: 1 1;
  }

  &__order-content-column {
    padding-top: $spacing-150;
    width: $spacing-400;
    flex: 1 1;
  }

  &__quantity {
    margin-bottom: 0;
    color: $accent-700;

    &--cancelled {
      color: $negative-700;
    }
  }

  &__content {
    color: $ui-600;
  }

  &__item-name {
    flex: 1 1;

    &--cancelled {
      text-decoration: line-through;
      color: $negative-500;
    }

    &-container {
      &--cancelled {
        color: $negative-500;
      }
    }

    &__spacer-dot {
      min-width: 10px;
      text-align: center;
      padding-bottom: 7px;
      margin-inline: 10px;
    }
  }

  &__item-price {
    padding-left: 1rem;

    &--cancelled {
      text-decoration: line-through;
      color: $negative-500;
    }
  }
}
