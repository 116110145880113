@import "stylesheets/base";

.order-item {
  display: block;
  padding: $spacing-200;
  border: solid 1px $ui-200;
  border-radius: $spacing-150;
  position: relative;
  margin-bottom: $spacing-100;

  &__status-label {
    margin-bottom: $spacing-50;
    font-weight: 500 !important;
  }

  &__cancelled {
    padding-left: $spacing-100;
    color: $negative-500;
    font-weight: 500 !important;
  }

  &__not-cancelled {
    padding-left: $spacing-100;
    color: $accent-500;
    font-weight: 500 !important;
  }

  &__count-and-cost {
    margin-bottom: 0;
  }

  &__arrow {
    position: absolute;
    top: $spacing-200;
    right: $spacing-150;

    svg {
      path {
        fill: $ui-1000;
      }
    }
  }

  &__heading {
    padding-right: $spacing-250;
  }

  &__link {
    text-decoration: none;
  }

  &:hover {
    background: $ui-100;
  }

  &:focus {
    outline: none;
    border-color: $accent-600;
    box-shadow: inset 0 0 0 2px $accent-600, 0 0 0 2px $accent-600;
  }
}
