@import "stylesheets/base";

.pharmacy-list-section {
  &__container {
    box-sizing: border-box;
    padding: 2rem !important;
    width: 100% !important;
    max-width: 1240px !important;

    &--wrapper {
      margin: 0 auto 1.5rem !important;
      padding: 2rem 1rem 1rem !important;
      width: calc(100% - 2rem) !important;
      max-width: $breakpoint-xl !important;
      border-radius: 12px;
      filter: drop-shadow(0 4px 16px rgba(219, 224, 229, 0.5));
      background-color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  &__heading {
    font-weight: 600;
    min-height: 4rem;
    display: flex;
    align-items: center;

    &--wrapper {
      margin-inline: auto;
      text-align: center;
    }
  }

  &__pharmacies {
    &__container {
      margin-top: 1rem;
      margin-inline: auto;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
      gap: 1rem;

      &--wrapper {
        max-width: 100%;
        grid-template-columns: repeat(auto-fit, minmax(300px, 600px));

        @media screen and (min-width: $breakpoint-md) {
          grid-template-columns: repeat(auto-fit, minmax(300px, 350px));
        }

        @media screen and (min-width: $breakpoint-lg) {
          grid-template-columns: repeat(auto-fit, minmax(300px, 350px));
        }
      }
    }

    &__cta {
      width: 100%;
      text-align: center;
      margin-block: 1rem;

      @media screen and (min-width: $breakpoint-lg) {
        margin-block: 2rem;
      }

      a {
        margin: 0;
      }
    }
  }
}
