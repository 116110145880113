@import "stylesheets/base";

.pmed-questionnaire {
  padding-bottom: $spacing-500;

  &__title {
    margin-bottom: $spacing-300;
  }

  &__link {
    margin-top: -$spacing-100;
    display: block;
    font-family: $font-heading;
    font-size: #{rem(17)};
    color: $accent-700;
    font-weight: 600;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
