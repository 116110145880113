@import "stylesheets/base";

.accordion {
  &__container {
    padding: 1rem 0;
  }

  &__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__button {
    background: unset;
    border: unset;
    font: unset;
    padding: unset;
    cursor: pointer;
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
  }

  &__divider {
    background-color: $accent-700;
    border-width: 0;
    height: 3px;
  }

  &__content {
    height: auto;
  }

  &__content-closed {
    height: 0;
    opacity: 0;

    * {
      pointer-events: none;
    }
  }
}
