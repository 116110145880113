.icon-with-label {
  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    &--lg {
      min-height: 4rem;
    }
  }

  &__text {
    flex: 1;
    margin: 0 !important;
    padding: 0 !important;

    a {
      text-decoration-color: black;
      color: black;
    }

    &--wrap {
      overflow-wrap: break-word;
      word-break: break-all;
    }

    &--lg {
      font-weight: 600;
    }
  }
}
