@import "stylesheets/base";

.pill-link-section {
  background-color: $accent-100;
  padding-block: 3rem;
  margin-block: 2rem;

  &__container {
    max-width: $breakpoint-lg !important;
  }

  &__heading {
    color: $accent-700;
    margin-bottom: 2rem;
    text-align: center;

    @media screen and(min-width: $breakpoint-md) {
      text-align: left;
    }
  }

  &__links {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 1rem;

    @media screen and(min-width: $breakpoint-sm) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media screen and(min-width: $breakpoint-md) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media screen and(min-width: $breakpoint-lg) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}

.pill-link {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  padding: 0.5rem;
  border-radius: 0.75rem;
  box-shadow: 0 4px 16px 0 rgba(219, 224, 229, 0.5);
  text-decoration: none !important;

  &:hover {
    background-color: $ui-100;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    transform: scale(1.02);
  }

  &:focus {
    outline: none;
    background-color: $ui-100;
    box-shadow: 0 0 0 3px $accent-500, 0 4px 16px 0 $ui-200;
  }

  &:active {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(2px); /* Depress slightly when clicked */
  }

  * {
    color: $accent-500;
    margin: 0;
  }

  &__label {
    flex: 1;
  }

  &__icon {
    width: 24px;
    height: 24px;
  }
}
