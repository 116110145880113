@import "abstracts/reset";
@import "base";
@import "global/grid";
@import "global/typography";

// stylelint-disable
html {
  overflow: hidden;
}

body,
#root {
  &.open,
  &.open #root {
    height: 100%;
    overflow-x: hidden;
  }
  overflow-x: hidden;
}
