@import "stylesheets/base";

.quantity-selector {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    max-width: 3rem;
    background: $accent-500;
    flex: 1;

    svg path {
      fill: $ui-0;
    }

    &:hover {
      background: $accent-700;
      cursor: pointer;
    }

    &:focus {
      box-shadow: none;
    }
  }

  &__quantity {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    min-width: 3rem;
    max-width: 3rem;
    flex: 1;
    background: $ui-100;
    border-radius: $spacing-100;
  }
}
