@import "stylesheets/base";

.product-detail {
  max-width: $breakpoint-lg !important;

  &__top-row {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 2rem;
    margin-block: 2rem;

    @media screen and (min-width: $breakpoint-sm) {
      flex-direction: row;
      margin-bottom: 4rem;
    }

    @media screen and (min-width: $breakpoint-md) {
      gap: 4rem;
    }

    &__image-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      @media screen and (min-width: $breakpoint-sm) {
        max-width: 300px;
      }

      @media screen and (min-width: $breakpoint-md) {
        flex: 1;
        flex-basis: 40%;
        max-width: 400px;
      }

      &__image {
        width: 100%;
        max-width: 300px;

        @media screen and (min-width: $breakpoint-md) {
          max-width: 400px;
        }
      }
    }

    &__product-details-container {
      flex: 1;
      flex-basis: 40%;
      width: 100%;

      @media screen and (min-width: $breakpoint-md) {
        max-width: 500px;
      }
    }
  }

  &__subtotal {
    margin: 0;
  }

  &__price {
    margin: 0 0 $spacing-150 0;
    padding-bottom: 1rem;
  }

  &__features {
    text-align: left;
    font-weight: bold;
  }

  &__information-container {
    margin-block: 2rem;
  }

  &__info-msg,
  &__error-msg {
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: left;
    gap: 0.5rem;
    line-height: 1.375rem;
    font-weight: 500;
    margin-top: 1rem;

    &__icon {
      flex-shrink: 0;
    }
  }

  &__error-msg {
    color: $negative-500;

    svg {
      path {
        fill: $negative-500;
      }
    }
  }

  &__tile-section-overrides {
    &__list > ul {
      gap: 1rem;
      grid-template-columns: repeat(1, 1fr);

      @media screen and(min-width: 350px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and(min-width: 575px) {
        grid-template-columns: repeat(3, 1fr);
      }

      @media screen and(min-width: $breakpoint-lg) {
        grid-template-columns: repeat(6, 1fr);
      }
    }

    &__no-padding {
      padding-inline: 0 !important;
    }
  }
}

.back-to-pom-results {
  // hardcoded to match <Breadcrumb /> style
  $root: &;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: $breakpoint-xl;
  margin-inline: auto;
  padding: $spacing-200;
  font-family: $font-heading;
  font-weight: 600;
  font-size: #{rem(19)};
  line-height: #{rem(24)};
  letter-spacing: #{rem(-0.25)};
  min-height: #{rem(64)};

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $accent-500;
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }

    &__icon {
      flex: 0 0 24px;
    }

    span {
      margin-top: 1px;
    }

    svg {
      path {
        fill: $ui-1000;
      }
    }
  }
}
