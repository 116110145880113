@import "stylesheets/base";

.footer {
  margin-top: 4rem;
  background: $footer-background;
  padding: 2rem 3.5rem;

  p,
  h6 {
    color: white;
  }

  &__content {
    max-width: $breakpoint-xl;
    margin-inline: auto;
    width: 100%;

    &__top-row {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      padding-left: 2rem;
      gap: 2rem;

      @media screen and (min-width: $breakpoint-md) {
        padding-left: 0;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }

    &__links {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      @media screen and (min-width: $breakpoint-md) {
        flex-direction: row;
        justify-content: flex-end;
        gap: 2rem;
      }

      @media screen and (min-width: $breakpoint-lg) {
        gap: 4rem;
      }

      h6,
      a {
        font-weight: lighter !important;
        text-decoration: none !important;

        @media screen and (min-width: $breakpoint-md) {
          text-align: center;
        }
      }
    }

    &__copyright {
      margin-top: 2rem;
      max-width: 250px;

      @media screen and (min-width: $breakpoint-sm) {
        padding-left: 2rem;
        max-width: unset;
      }

      @media screen and (min-width: $breakpoint-md) {
        text-align: right;
      }

      p {
        font-weight: lighter !important;
      }
    }

    // &__logo {
    // }
  }

  // &__info {
  //   background: $footer-background;
  //   padding: $spacing-600 $spacing-600 $spacing-400;
  // }

  // &__logo {
  //   margin-bottom: $spacing-400;
  // }

  // &__section {
  //   padding: 0 !important; /* stylelint-disable-line declaration-no-important */
  //   margin-bottom: $spacing-300;

  //   a {
  //     text-decoration-color: white;
  //   }
  // }

  // &__facebook {
  //   svg {
  //     width: 16px;
  //     height: 16px;
  //   }
  // }

  // &__section-header {
  //   font-weight: bold;
  //   border-bottom: 1px solid $footer-accent;
  //   padding-bottom: $spacing-200;
  //   margin-bottom: $spacing-100;
  // }

  // &__nhs {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: flex-end;
  //   border-top: 1px solid $footer-accent;
  //   margin-top: $spacing-600;
  //   padding-top: $spacing-300;

  //   svg {
  //     width: 60px;
  //   }
  // }

  // &__legal {
  //   background: $footer-background-darker;
  //   padding: $spacing-400 $spacing-600 $spacing-400;

  //   > div {
  //     padding: 0 !important; /* stylelint-disable-line declaration-no-important */
  //     display: block;
  //     flex-wrap: wrap;
  //     justify-content: space-between;
  //   }
  // }

  // &__legal-links {
  //   a {
  //     text-decoration: none;
  //   }

  //   p {
  //     margin: $spacing-100 0;
  //   }
  // }

  // @media screen and (min-width: $breakpoint-md) {
  //   margin-bottom: 0;

  //   &__section {
  //     &:last-child {
  //       text-align: end;

  //       p {
  //         padding-right: $spacing-500;
  //       }
  //     }
  //   }

  //   &__facebook {
  //     svg {
  //       cursor: pointer;
  //       margin-right: $spacing-900;
  //     }
  //   }

  //   &__legal {
  //     div {
  //       display: flex;
  //       flex-wrap: wrap;
  //       justify-content: space-between;
  //       align-items: center;
  //     }
  //   }

  //   &__legal-links {
  //     display: flex;
  //     flex-wrap: wrap;
  //     justify-content: flex-end;

  //     a {
  //       margin-left: $spacing-300;
  //     }
  //   }
  // }
}
