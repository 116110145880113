@import "stylesheets/base";

.empty-basket-cta {
  max-width: 23rem;
  margin: 0 auto;
  margin-top: 4rem;
  text-align: center;

  &__illustration {
    width: 100%;
    height: 16rem;
    margin-bottom: 1rem;
  }

  &__heading {
    margin-bottom: 2rem;
  }

  &__ctas {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;

    @media screen and (min-width: $breakpoint-sm) {
      flex-direction: row;
      justify-content: center;
      gap: 1rem;

      button {
        width: 16rem;
      }
    }
  }
}
