@import "stylesheets/base";

.landing-page {
  &__booking-process-section {
    padding-block: 4rem 4rem !important;

    @media screen and (min-width: $breakpoint-lg) {
      padding-bottom: 4.5rem !important;
    }
  }

  &__pill-link-section {
    margin-bottom: 0 !important;
  }

  &__hero-section {
    margin-top: 2rem !important;
  }

  &__footer-overrides {
    margin-top: 0;
  }
}
