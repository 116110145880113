@import "stylesheets/base";

.product-tile-section {
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100% !important;
  text-decoration: none;

  &__title {
    margin-bottom: 1rem;
  }

  &__body-text {
    margin-bottom: 1rem;
  }

  &__view-more {
    display: flex;
    align-items: center;
    justify-content: right;
    line-height: 1.375rem;
    font-weight: 500;
    margin-top: 1rem;
    color: $accent-700;
    font-size: #{rem(17)};
    padding: 1rem 0;

    &__icon {
      flex-shrink: 0;
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    max-width: $breakpoint-xl !important;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}
