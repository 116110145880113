@import "stylesheets/base";

.pomform {
  &__container {
    max-width: $breakpoint-md !important;
    width: 100% !important;
  }

  &__progress-container {
    display: flex;
    justify-content: center;

    @media screen and (min-width: $breakpoint-sm) {
      margin-block: 1rem;
    }

    @media screen and (min-width: $breakpoint-md) {
      margin-block: 2rem;
    }
  }

  &__heading {
    margin-bottom: 2rem;
  }

  &__input {
    &--half-width {
      width: 100%;

      @media screen and (min-width: $breakpoint-md) {
        width: 50%;
      }

      label {
        flex-basis: 50%;
      }
    }

    &__checkbox {
      margin-bottom: 0;

      &:last-of-type {
        margin-bottom: 1.5rem !important;
      }

      label {
        margin-bottom: 10px !important;
      }
    }

    &__textarea {
      min-height: 150px;
    }

    &__file-upload {
      margin-bottom: 1.5rem;
    }

    &__terms-helper {
      font-size: $paragraph-md-font-size;
      font-weight: $paragraph-md-font-weight;
      letter-spacing: $paragraph-md-letter-spacing;
      line-height: $paragraph-md-line-height;
      margin-top: 0.5rem;
      color: $ui-500;
      display: block;
      margin-bottom: 1rem;

      &__list {
        list-style: disc;
        margin-bottom: 1rem;

        &__list-item {
          margin-left: 2rem;
        }
      }
    }
  }

  &__button-container {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 4rem;

    @media screen and (min-width: $breakpoint-sm) {
      flex-direction: row;
      justify-content: flex-end;
      gap: 1rem;

      button {
        width: 200px;
        max-width: 200px !important;
      }
    }
  }
}
