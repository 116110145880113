@import "stylesheets/base";

.search-bar {
  &__container {
    display: flex;
    flex-basis: 500px;
    align-items: stretch;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 0 0 1px $accent-500;
    transition: all 0.25s ease-in-out;

    &:focus-within {
      box-shadow: 0 0 0 2px $accent-500;

      button {
        box-shadow: 0 0 0 2px $accent-500;
      }
    }

    &--error {
      box-shadow: 0 0 0 2px $negative-500 !important;
      animation: shake 0.5s ease;
    }
  }

  &__input {
    flex: 1;
    border: transparent;
    border-radius: 0.5rem;
    outline: none;
    padding-left: 0.75rem;
    font-size: 1rem;
  }

  &__button {
    background-color: $accent-500;
    border-radius: 0 0.5rem 0.5rem 0;
    cursor: pointer;
    transition: all 0.25s ease-in-out;

    * {
      fill: white;
    }

    &:hover {
      background-color: $accent-600;
    }

    &:focus {
      background-color: $accent-600;
    }

    &--error {
      box-shadow: 0 0 0 1px $negative-500 !important;
      background-color: $negative-500 !important;
    }
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(5px);
  }

  75% {
    transform: translateX(-5px);
  }

  100% {
    transform: translateX(0);
  }
}
