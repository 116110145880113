@import "stylesheets/base";

.page-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;

  &__navbar {
    flex: 0;
  }

  &__content {
    flex: 1;
  }

  &__footer {
    flex: 0;
  }
}
