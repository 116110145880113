@import "stylesheets/base";

.prescription-add {
  [for="input__text-autocomplete"] {
    display: none;
  }

  &__content {
    text-align: center;
  }

  &__modal-text {
    margin-bottom: $spacing-200;
  }

  &__modal-title {
    margin-bottom: $spacing-150;
  }

  &__image {
    margin: $spacing-1000 $spacing-200;
  }

  &__modal-button {
    width: 100%;
    margin-top: $spacing-300;
  }

  &__submit-button {
    margin: 0 0 1rem;
    flex-basis: 50%;

    @media screen and (min-width: $breakpoint-md) {
      margin: 0 0 0 1rem;
    }
  }

  &__help-button {
    margin: 0 !important;
    flex-basis: 50%;
  }
}
