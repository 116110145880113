@import "stylesheets/base";

.prescription-detail {
  &__title {
    margin-bottom: $spacing-200;
  }

  &__card {
    border: 1px solid $ui-200;
    border-radius: $spacing-150;
    padding: $spacing-200;
    margin-bottom: $spacing-150;
  }

  &__card-button {
    margin-bottom: 0;
  }

  &__hr {
    border: 0;
    border-bottom: 1px solid $ui-200;
    margin: $spacing-300 $spacing-400;
  }

  &__delete,
  &__modal-button {
    width: 100%;
    margin: 0 0 $spacing-200 0;
  }

  &__modal-button:last-child {
    margin: 0;
  }

  &__modal-text {
    margin-bottom: $spacing-300;
  }

  &__info-text {
    text-align: center;
    color: $ui-500;
  }
}
