@import "stylesheets/base";

.icon-btn {
  background: transparent;
  border: 0;
  border-radius: $spacing-100;
  padding: $spacing-100;

  &__text {
    @include sr-only;
  }

  &__icon {
    display: block;
  }

  &:hover {
    background: $ui-200;
    cursor: pointer;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $accent-600, inset 0 0 0 1px $accent-600;
  }

  &--mid {
    background: $ui-200;
  }

  &:disabled {
    opacity: 0.7;
    background: $ui-200;

    svg path {
      fill: $ui-1000;
    }
  }
}
