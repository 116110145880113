@import "stylesheets/base";

.condition-banner {
  &__gradient {
    background: linear-gradient(#cde7e1, #cde7e100);
  }

  &__top-section {
    &__container {
      width: 100% !important;
      max-width: $breakpoint-xl;
      margin-inline: auto;
      padding-inline: 1.5rem;

      @media screen and (min-width: $breakpoint-xl) {
        padding-inline: 2rem;
      }
    }

    &__row {
      margin: 0;
      padding: 0;
      margin-top: 1rem;
      position: relative;

      @media screen and (min-width: $breakpoint-xl) {
        margin-top: 2rem;
      }
    }
  }

  &__lawsat-symbol {
    width: 169px;
    height: 169px;
    position: absolute;
    float: right;
    top: -60px;
    right: 16px;

    @media screen and (min-width: $breakpoint-xl) {
      top: -85px;
      right: -67px;
    }
  }
}
