@import "stylesheets/base";

.navbar {
  padding: $spacing-200;
  max-width: 1600px;
  margin-inline: auto;

  &__logo {
    width: 152px;
    height: 56px;
  }

  &__symbol-logo {
    display: flex;
    align-items: center;
    padding-inline: 0.5rem;
  }

  &__desktop {
    display: none;

    &__top-row {
      padding-bottom: 0.5rem;
      border-bottom: 2px solid $ui-200;
      margin-bottom: 0.5rem;
    }
  }

  &__mobile {
    &__top-row {
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (min-width: 350px) {
        gap: 0.5rem;
      }
    }

    &-title-logo {
      flex: 1;
      text-align: center;
      margin: 0 !important;
      padding: 0 !important;
    }

    &__search-bar__container {
      margin-top: 1rem;
      background-color: white;
      transition: all 0.3s ease-in-out;
      visibility: hidden;
      opacity: 0;
      max-height: 0;

      &--show {
        opacity: 1;
        visibility: visible;
        z-index: 3000;
        transition: all 0.3s ease-in-out;
        max-height: 500px;
      }
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    &__desktop {
      display: block;

      &__top-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &__mobile {
      display: none;
    }
  }

  &__menu {
    display: none;

    &--open {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: white;
      z-index: 2000;
      overflow: hidden;
    }
  }

  &__menu-close {
    position: absolute;
    left: 24px;
    top: 16px;
  }

  &__main-logo {
    position: absolute;
    bottom: 32px;
    width: 152px;
    height: 56px;
  }

  &__icons {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;

    &--active {
      span {
        color: $accent-500;
        text-decoration: underline;

        svg {
          path {
            fill: $accent-500;
          }
        }
      }
    }
  }
}
