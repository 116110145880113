@import "stylesheets/base";

.dependant-address-search {
  margin-top: $spacing-150;

  &__content {
    text-align: center;
  }

  &__image {
    margin-bottom: $spacing-200;
  }

  &__text {
    margin-bottom: $spacing-300;
  }

  &__submit-button {
    margin-bottom: $spacing-100;

    @media screen and (min-width: $breakpoint-md) {
      margin-left: $spacing-200;
    }
  }
}
