@import "stylesheets/base";

.landing-page-help-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: $spacing-200;

  &__icon-container {
    display: flex;
    background: #31a171;
    border-radius: 10px;
    width: 64px;
    height: 64px;
    padding: $spacing-200;
    justify-content: center;
    align-items: center;
    margin-bottom: $spacing-300;

    svg {
      path {
        fill: white;
        color: white;
      }
    }
  }

  &__label {
    text-align: center;
  }
}
