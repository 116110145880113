@import "stylesheets/base";

.pagination-selector {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-self: center;

  &__selector-container {
    display: flex;
    gap: 0.5rem;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  &__page-counter {
    text-align: center;
    margin-inline: auto;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    max-width: 3rem;
    background: $accent-500;
    flex: 1;

    svg path {
      fill: $ui-0;
    }

    &:hover {
      background: $accent-700;
      cursor: pointer;
    }

    &:focus {
      box-shadow: none;
    }
  }

  &__page-number {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    min-width: 3rem;
    max-width: 3rem;
    flex: 1;
    background: $ui-100;
    border-radius: $spacing-100;
  }
}
