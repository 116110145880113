@import "stylesheets/base";

.dependant-select-pharmacy {
  padding-bottom: $spacing-900;

  &__form {
    margin-top: $spacing-300;
  }

  &__progress-container {
    display: flex;
    justify-content: center;

    @media screen and (min-width: $breakpoint-sm) {
      margin-block: 1rem;
    }

    @media screen and (min-width: $breakpoint-md) {
      margin-block: 2rem;
    }
  }
}
