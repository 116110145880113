@import "stylesheets/base";

.prescription-exemption {
  $this: &;

  &__progress-container {
    display: flex;
    justify-content: center;

    @media screen and (min-width: $breakpoint-sm) {
      margin-block: 1rem;
    }

    @media screen and (min-width: $breakpoint-md) {
      margin-block: 2rem;
    }
  }

  &__content {
    position: relative;
    display: block;
    border: 1px solid $ui-200;
    border-radius: $spacing-150;
    padding: $spacing-200 $spacing-200 $spacing-200 $spacing-650;

    @media screen and (min-width: $breakpoint-md) {
      max-width: 450px;
      margin: $spacing-300 auto;
    }
  }

  &__text p {
    margin-bottom: $spacing-50;
  }

  &__button {
    width: 100%;
    margin-bottom: $spacing-200;

    @media screen and (min-width: $breakpoint-md) {
      margin-bottom: 0;
      //stylelint-disable declaration-no-important
      //TO-DO design inconsistant here on buttonwidth for desktop. Check with Sean when back on project.
      width: 100% !important;
    }
  }

  &__button-set {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $breakpoint-md) {
      flex-direction: row;

      #{$this}__button:first-child {
        margin-right: $spacing-300;
      }
    }
  }

  &__info-icon {
    position: absolute;
    left: $spacing-200;
  }

  &__list::before {
    content: none;
  }

  &__remove-button {
    @media screen and (min-width: $breakpoint-md) {
      margin-inline: 0 8px !important;
    }
  }

  &__remove-button,
  &__remove-button:hover,
  &__remove-button:active,
  &__remove-button:focus {
    background: $negative-200;
    color: $negative-700;
    box-shadow: none;
  }
}
