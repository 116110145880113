@import "stylesheets/base";

.payment-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin-inline: auto;

  &__title {
    margin: $spacing-300 0 0 0;
    text-align: center;
  }

  &__description {
    margin: $spacing-50 0 0 0;
    text-align: center;
  }

  &__link-panel {
    margin-top: $spacing-300;
  }

  &__button {
    margin-top: $spacing-300;
    width: 100%;
  }

  &__order-summary {
    width: 100%;
    max-width: 500px;
    box-shadow: 0 4px 16px 0 rgba(219, 224, 229, 0.5);
    padding: 1rem 2rem;
    margin-top: 2rem;

    &__heading {
      display: inline;
    }

    &__order-id {
      display: inline;
      margin-left: 0.5rem;
      color: $ui-500;
    }

    &__order-total {
      margin-top: 2rem;
      width: 100%;
      max-width: 400px;
      text-align: left;
    }
  }

  &__buttons {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;

    @media screen and (min-width: $breakpoint-md) {
      flex-direction: row;
    }

    a {
      flex: 1;
      flex-basis: 100%;
      margin: 0;
    }
  }

  > svg {
    width: 300px;
    height: auto;
  }
}
