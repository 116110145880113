@import "stylesheets/base";

.dependant-contact-details {
  display: block;

  &__form {
    margin-top: $spacing-300;
  }

  &__progress-container {
    display: flex;
    justify-content: center;

    @media screen and (min-width: $breakpoint-sm) {
      margin-block: 1rem;
    }

    @media screen and (min-width: $breakpoint-md) {
      margin-block: 2rem;
    }
  }

  &__dateFields {
    display: flex;

    label {
      font-family: $font-body;
      font-size: $paragraph-md-font-size;
      font-weight: normal !important; /* stylelint-disable-line declaration-no-important */
      color: $ui-500;
    }
  }

  &__dateInput {
    flex-grow: 1;

    &:not(:last-child) {
      margin-right: $spacing-150;
    }
  }

  &__text-validation {
    display: block;
    color: $negative-500;
    margin-top: -$spacing-100;
    margin-bottom: $spacing-150;
  }
}
