@import "stylesheets/base";

.patient-dependant-added {
  padding: $spacing-1000 0;

  &__content {
    text-align: center;
    margin: $spacing-500 auto $spacing-300;
  }

  &__image {
    margin-bottom: $spacing-300;
  }

  &__link-container {
    position: relative;
    color: $ui-1000;
    text-decoration: none;
    display: block;
    z-index: 999;
  }

  &__link {
    display: block;
    padding: 18px;
    text-decoration: none;
    border: solid 1px $ui-200;
    border-radius: $spacing-150;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin-top: $spacing-300;
  }

  &__link-content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__link-text {
    margin-left: 14px;
    margin-bottom: 0;
  }

  &__arrow {
    position: absolute;
    right: $spacing-100;

    svg {
      path {
        fill: $ui-1000;
      }
    }
  }

  &__button {
    display: block;
    width: 100%;
    margin: $spacing-300 auto $spacing-300;
  }

  &__info {
    color: #63717e;
    align-content: center;
    margin: auto;
    max-width: #{rem(295)};
  }
}
