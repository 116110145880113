@import "stylesheets/base";

.link-panel {
  width: 100%;
  text-decoration: none;
  position: relative;
  background-color: $ui-0;
  box-shadow: 0 0 0 1px $ui-200;
  border-radius: $spacing-150;
  padding: $spacing-200 $spacing-600 $spacing-200 $spacing-650;
  outline: none;
  transition: all 0.25s ease-in-out;

  &:hover {
    background-color: $ui-100;
  }

  &:focus {
    background-color: $ui-100;
    box-shadow: 0 0 0 4px $accent-600;
  }

  &__title {
    margin-bottom: $spacing-50;
  }

  &__main-icon {
    position: absolute;
    left: $spacing-200;
    top: $spacing-200;
  }

  &__arrow-icon {
    position: absolute;
    right: $spacing-150;
    top: $spacing-150;
  }
}
