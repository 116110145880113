@import "stylesheets/base";

$variants: (
  positive $positive-100 $positive-200 $positive-300 $positive-500 $positive-600
    $positive-100,
  warning $warning-100 $warning-200 $warning-300 $warning-500 $warning-700
    $warning-100,
  negative $negative-200 $negative-200 $negative-300 $negative-500 $negative-500
    $negative-100
);

$floating: (
  lg $label-lg-font-size $label-lg-font-weight $label-lg-letter-spacing
    $label-lg-line-height,
  md $label-md-font-size $label-md-font-weight $label-md-letter-spacing
    $label-md-line-height,
  sm $label-sm-font-size $label-sm-font-weight $label-sm-letter-spacing
    $label-sm-line-height
);

.input__text-group {
  margin-bottom: $spacing-300;

  &--lg {
    .input__text-label {
      font-size: $label-lg-font-size;
      font-weight: $label-lg-font-weight;
      letter-spacing: $label-lg-letter-spacing;
      line-height: $label-lg-line-height;
      margin-bottom: 10px;
    }

    .input__text-input {
      font-size: $paragraph-lg-font-size;
      font-weight: $paragraph-lg-font-weight;
      letter-spacing: $paragraph-lg-letter-spacing;
      line-height: $paragraph-lg-line-height;
      padding: 10px;
      margin-bottom: $spacing-150;
    }

    .input__text-caption,
    .input__text-validation {
      font-size: $paragraph-md-font-size;
      font-weight: $paragraph-md-font-weight;
      letter-spacing: $paragraph-md-letter-spacing;
      line-height: $paragraph-md-line-height;
    }

    .input-text-validation {
      margin-top: -$spacing-100;
      margin-bottom: $spacing-150;
    }
  }

  &--md {
    .input__text-label {
      font-size: $label-sm-font-size;
      font-weight: $label-sm-font-weight;
      letter-spacing: $label-sm-letter-spacing;
      line-height: $label-sm-line-height;
      margin-bottom: 8px;
    }

    .input__text-input {
      font-size: $paragraph-md-font-size;
      font-weight: $paragraph-md-font-weight;
      letter-spacing: $paragraph-md-letter-spacing;
      line-height: $paragraph-md-line-height;
      padding: 8px;
      margin-bottom: 8px;
    }

    .input__text-caption,
    .input__text-validation {
      font-size: $paragraph-sm-font-size;
      font-weight: $paragraph-sm-font-weight;
      letter-spacing: $paragraph-sm-letter-spacing;
      line-height: $paragraph-sm-line-height;
    }

    .input__text-validation {
      margin-bottom: $spacing-150;
      margin-top: -$spacing-100;
    }
  }

  &--sm {
    .input__text-label {
      font-size: $label-xs-font-size;
      font-weight: $label-xs-font-weight;
      line-height: $label-xs-line-height;
      letter-spacing: $label-xs-letter-spacing;
      margin-bottom: 4px;
    }

    .input__text-input {
      font-size: $paragraph-sm-font-size;
      font-weight: $paragraph-sm-font-weight;
      line-height: $paragraph-sm-line-height;
      letter-spacing: $paragraph-sm-letter-spacing;
      padding: 6px;
      margin-bottom: 4px;
    }

    .input__text-caption,
    .input__text-validation {
      font-size: $paragraph-xs-font-size;
      font-weight: $paragraph-xs-font-weight;
      line-height: $paragraph-xs-line-height;
      letter-spacing: $paragraph-xs-letter-spacing;
    }
  }

  .input__text-label {
    display: block;

    .input__text-label-text {
      display: block;
    }
  }

  .input__text-container {
    position: relative;

    // stylelint-disable selector-pseudo-class-no-unknown
    :global(.validation) {
      position: absolute;
      bottom: 22px;
      right: 10px;
    }
    // stylelint-enable selector-pseudo-class-no-unknown
  }

  .input__text-input {
    background: $ui-200;
    border: none;
    box-shadow: 0 0 0 1px $ui-200;
    color: $ui-1000;
    padding: $spacing-100 $spacing-200;
    width: 100%;

    &:hover {
      box-shadow: 0 0 0 1px $ui-300;
      transition: all 0.25s ease-in-out;
    }

    &:focus {
      box-shadow: 0 0 0 3px $accent-600;
      outline: none;
      transition: all 0.25s ease-in-out;
    }

    &:active {
      box-shadow: 0 0 0 2px $ui-1000;
      transition: all 0.25s ease-in-out;
    }

    &:disabled {
      opacity: 0.5;
    }

    &:read-only {
      background: none;
      box-shadow: none;
      color: $ui-1000;
      padding: 10px 0;
    }

    &::placeholder {
      color: $ui-700;
    }

    &--rounded {
      border-width: 1px;
      border-radius: 8px;
    }

    &--pill {
      border-width: 1px;
      border-radius: 24px;
    }

    &--accent {
      background: $ui-200;
      box-shadow: 0 0 0 2px $ui-200;

      &:active {
        background: $accent-100;
        box-shadow: 0 0 0 2px $accent-500;
      }

      &:focus {
        background: $accent-100;
        box-shadow: 0 0 0 3px $accent-600;
      }
    }

    &--outline {
      background: $ui-0;
    }

    &--warning,
    &--negative {
      padding-right: 40px;
    }

    @each $variant in $variants {
      &--#{nth($variant, 1)} {
        background: #{nth($variant, 2)};
        box-shadow: 0 0 0 1px #{nth($variant, 3)};

        // stylelint-disable selector-pseudo-class-no-unknown
        + :global(.validation) path {
          stroke: transparent;
          fill: #{nth($variant, 6)};
          transition: all 0.25s ease-in-out;
        }

        &:hover {
          box-shadow: 0 0 0 1px #{nth($variant, 4)};
          transition: all 0.25s ease-in-out;

          + :global(.validation) path {
            stroke: transparent;
            fill: #{nth($variant, 6)};
            transition: all 0.25s ease-in-out;
          }
        }

        &:active {
          box-shadow: 0 0 0 2px #{nth($variant, 5)};
          background: #{nth($variant, 7)};

          + :global(.validation) path {
            stroke: transparent;
            fill: #{nth($variant, 6)};
            transition: all 0.25s ease-in-out;
          }
        }

        &:focus {
          box-shadow: 0 0 0 3px #{nth($variant, 6)};
          background: #{nth($variant, 7)};

          + :global(.validation) path {
            stroke: transparent;
            fill: #{nth($variant, 6)};
            transition: all 0.25s ease-in-out;
          }
        }
        // stylelint-enable selector-pseudo-class-no-unknown
      }
    }
  }

  .input__text-caption,
  .input__text-validation {
    margin-bottom: $spacing-150;
    margin-top: -$spacing-100;
    color: $ui-500;
    display: block;
  }

  &.input__text-group--negative {
    .input__text-validation,
    .input__text-label {
      color: $negative-500;
    }
  }

  &.input__text-group--warning {
    .input__text-validation {
      color: $warning-600;
    }
  }

  &.input__text-group--icon {
    .input__text-input {
      padding-left: $spacing-450;
    }

    // stylelint-disable selector-pseudo-class-no-unknown
    :global(.prefix) {
      position: absolute;
      bottom: 23px;
      left: $spacing-100;
    }

    :global(.validation) path {
      stroke: transparent;
      fill: $negative-500;
      transition: all 0.25s ease-in-out;
    }
    // stylelint-enable selector-pseudo-class-no-unknown
  }

  &.input__text-group--floating {
    position: relative;
    margin-bottom: 10px;

    .input__text-label {
      color: $ui-700;
      margin: 0;
      position: absolute;
      top: 25px;
      left: 16px;
      transform: translateY(-8px);
      transition: all 0.25s ease-in-out;
    }

    @each $float in $floating {
      &.input__text-group--#{nth($float, 1)} {
        .input__text-label {
          font-size: #{nth($float, 2)};
          font-weight: #{nth($float, 3)};
          letter-spacing: #{nth($float, 4)};
          line-height: #{nth($float, 5)};
        }
      }
    }

    .input__text-input {
      padding: 22px 16px 0;
      line-height: 2;

      &::placeholder {
        color: transparent;
      }

      // stylelint-disable selector-max-specificity
      &:active,
      &:focus {
        &::placeholder {
          color: $ui-500;
        }

        + .input__text-label {
          font-size: $paragraph-sm-font-size;
          font-weight: $paragraph-sm-font-weight;
          line-height: $paragraph-sm-line-height;
          letter-spacing: $paragraph-sm-letter-spacing;
          transform: translateY(-20px);
        }
      }

      &:not(:placeholder-shown) {
        + .input__text-label {
          font-size: $paragraph-sm-font-size;
          font-weight: $paragraph-sm-font-weight;
          line-height: $paragraph-sm-line-height;
          letter-spacing: $paragraph-sm-letter-spacing;
          transform: translateY(-20px);
        }
      }

      &:read-only {
        padding-left: 0;

        &::placeholder {
          color: $ui-500;
        }

        + .input__text-label {
          font-size: $paragraph-sm-font-size;
          font-weight: $paragraph-sm-font-weight;
          line-height: $paragraph-sm-line-height;
          letter-spacing: $paragraph-sm-letter-spacing;
          left: 0;
          transform: translateY(-20px);
        }
      }
      // stylelint-enable selector-max-specificity
    }
  }
}
