@import "stylesheets/base";

.add-to-basket-button {
  width: 100%;
  margin-block: 0.5rem 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  &__success-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    width: fit-content;
    margin-block: 0;
    margin-inline: auto;
    background-color: white;
    transition: all 0.3s ease-in-out;
    visibility: hidden;
    opacity: 0;
    max-height: 0;

    &--show {
      margin-block: 1rem;
      opacity: 1;
      visibility: visible;
      z-index: 3000;
      transition: all 0.3s ease-in-out;
      max-height: 100px;
    }

    * {
      margin: 0;
      padding: 0;
      color: $accent-500;
    }
  }
}
