@import "stylesheets/base";

.modal {
  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: $ui-1000;
    opacity: 0.72;
    z-index: 2000;
  }

  &__modal {
    display: flex;
    flex-direction: row;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - #{$spacing-400});
    height: calc(100% - #{$spacing-1400});
    padding: $spacing-250;
    z-index: 3000;
    outline: none;
    pointer-events: none;

    @media screen and (min-width: $breakpoint-md) {
      max-width: 458px;
      padding: $spacing-250 0;
    }
  }

  &__container {
    pointer-events: auto;
    align-self: center;
    overflow: auto;
    position: relative;
    background: $ui-0;
    width: 100%;
    max-height: 100%;
    padding: $spacing-300;
    border-radius: $spacing-150;
  }

  &__inner {
    display: block;
    width: 100%;
  }

  &__close {
    position: absolute;
    top: $spacing-100;
    right: $spacing-100;
  }
}
