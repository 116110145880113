@import "stylesheets/base";

.pom-confirmation {
  &__container {
    max-width: $breakpoint-lg !important;
    width: 100% !important;
  }

  &__progress-container {
    display: flex;
    justify-content: center;

    @media screen and (min-width: $breakpoint-sm) {
      margin-block: 1rem;
    }

    @media screen and (min-width: $breakpoint-md) {
      margin-block: 2rem;
    }
  }

  &__heading {
    margin-bottom: 2rem;
  }

  &__product-info-container {
    display: flex;
    flex-direction: row;

    img {
      width: 100px;
    }
  }

  &__products-section {
    padding: 0 !important;
    margin-block: 4rem;
  }

  &__button-container {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 4rem;

    @media screen and (min-width: $breakpoint-sm) {
      flex-direction: row;
      justify-content: flex-end;
      gap: 1rem;

      a {
        width: 250px;
        max-width: 250px !important;
      }
    }
  }
}
