@import "stylesheets/base";

.dependant-address {
  text-align: left;
  padding-bottom: 80px;

  &__form {
    margin-top: $spacing-300;
  }

  &__submit-button {
    margin-bottom: $spacing-100;

    @media screen and (min-width: $breakpoint-md) {
      margin-left: $spacing-200;
    }
  }
}
