@import "stylesheets/base";

.account-patient-details {
  display: block;

  &__progress-container {
    display: flex;
    justify-content: center;

    @media screen and (min-width: $breakpoint-sm) {
      margin-block: 1rem;
    }

    @media screen and (min-width: $breakpoint-md) {
      margin-block: 2rem;
    }
  }

  &__form {
    margin-top: $spacing-300;
  }

  &__contact-number {
    &__container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 1rem;

      @media screen and (min-width: $breakpoint-sm) {
        flex-direction: row;
        gap: 1rem;
      }
    }

    &__country-code {
      flex-basis: 30%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      select {
        box-shadow: 0 0 0 2px $ui-200;
      }
    }

    &__number {
      flex-grow: 1;
    }
  }

  &__dateFields {
    display: flex;
    gap: 1rem;

    label {
      font-family: $font-body;
      font-size: $paragraph-md-font-size;
      font-weight: normal !important; /* stylelint-disable-line declaration-no-important */
      color: $ui-500;
    }
  }

  &__dateInput {
    flex-grow: 1;
  }

  &__text-validation {
    display: block;
    color: $negative-500;
    margin-top: -$spacing-100;
    margin-bottom: $spacing-150;
  }

  &__address-search {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;

    &__list {
      margin-bottom: 1.5rem;
    }

    &--hidden {
      display: none;
    }

    &__no-results {
      color: $negative-500;
      text-align: center;
      margin-bottom: 1rem;
    }
  }

  &__nhs-number--hidden {
    display: none;
  }

  &__postcode-search {
    &__container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: stretch;
      margin-bottom: 2rem;

      @media screen and (min-width: $breakpoint-lg) {
        flex-direction: row;
        justify-content: stretch;
        align-items: flex-end;
        gap: 2rem;
      }
    }

    &__input {
      flex: 1;
      margin: 0;

      input {
        margin: 0 !important;
        padding-block: 9px !important;
      }

      svg {
        margin-top: 0.75rem;
      }
    }

    &__search-button {
      margin: 0;
    }
  }

  &__switch-address-mode-button {
    margin-top: -1rem;
    text-align: right;
    align-self: flex-end;
  }
}
