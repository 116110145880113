@import "stylesheets/base";

.pom-confirm-login-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;

  > button {
    margin: 0 !important;
  }

  &__info-msg {
    display: flex;
    align-items: center;
    justify-content: left;
    text-align: left;
    gap: 0.5rem;
    line-height: 1.375rem;
    font-weight: 500;
    margin-block: 1rem;

    &__icon {
      flex-shrink: 0;
    }
  }

  &__content {
    margin-bottom: $spacing-400;
  }

  &__remove-button {
    margin-bottom: $spacing-200;
  }
}
