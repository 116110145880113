@import "stylesheets/base";

.product-list {
  &__list {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    @media screen and(min-width: 400px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media screen and(min-width: 605px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media screen and(min-width: $breakpoint-md) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @media screen and(min-width: $breakpoint-lg) {
      grid-template-columns: repeat(5, minmax(0, 1fr));
      gap: 1.5rem;
    }

    @media screen and(min-width: $breakpoint-xl) {
      grid-template-columns: repeat(6, minmax(0, 1fr));
      gap: 2.5rem;
    }

    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
}
