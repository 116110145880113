@import "stylesheets/base";

.terms-conditions {
  &__article {
    margin-bottom: $spacing-300;
  }

  &__article-list {
    list-style: none;
    padding: $spacing-100;
    margin-left: $spacing-400;
    margin-bottom: $spacing-150;
  }

  &__article-clause {
    padding: $spacing-100;
    margin-left: $spacing-250;
    list-style: decimal;
  }
}
