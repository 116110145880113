@import "stylesheets/base";

.gp-card {
  box-shadow: inset 0 0 0 1px $ui-200;
  border-radius: $spacing-150;

  margin-top: $spacing-300;

  padding: $spacing-200;

  text-align: center;

  &__icon {
    width: 34px;
    height: 34px;

    path {
      fill: $brand;
    }
  }

  &__link {
    margin-top: $spacing-300;
    border-top: 1px solid $ui-200;
    display: block;
    padding: $spacing-300 0 $spacing-100;
    font-family: $font-heading;
    font-size: #{rem(17)};
    color: $accent-600;
    font-weight: 600;
    text-decoration: none;
  }
}
