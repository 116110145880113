@import "stylesheets/base";

.pom-intermediary {
  margin-block: 4rem;

  &__container {
    max-width: $breakpoint-lg !important;
  }

  &__heading {
    margin-bottom: 1rem;
    text-align: left;
  }

  &__intro {
    margin-bottom: 1rem;
  }

  &__pills {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 1rem;

    @media screen and(min-width: $breakpoint-sm) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media screen and(min-width: $breakpoint-md) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media screen and(min-width: $breakpoint-lg) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }

  &__condition-info {
    &__container {
      margin-top: 2rem;
    }

    &__heading {
      margin-bottom: 1rem;
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 0.5rem;
      margin-top: 2rem;

      @media screen and(min-width: $breakpoint-sm) {
        gap: 1rem;
        flex-direction: row;
      }
    }
  }

  &__all-conditions-link {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.25rem;
    margin-top: 2rem;
    text-decoration: none !important;

    * {
      color: $accent-700;
      margin: 0;
    }
  }
}

.filter-pill {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  border-radius: 0.75rem;
  border: none;
  padding: 0.5rem;
  box-shadow: 0 4px 16px 0 rgba(219, 224, 229, 0.5);
  text-decoration: none !important;

  * {
    color: $accent-500;
    margin: 0;
  }

  &:hover {
    cursor: pointer;
    background-color: $ui-100;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    transform: scale(1.02);
  }

  &:focus {
    outline: none;
    background-color: $ui-100;
    box-shadow: 0 0 0 3px $accent-500, 0 4px 16px 0 $ui-200;
  }

  &:active {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(2px); /* Depress slightly when clicked */
  }

  &--active {
    background: $accent-500 !important;
    transform: translateY(0) !important; /* Depress slightly when clicked */

    * {
      color: white !important;
    }
  }

  &__label {
    flex: 1;
    text-align: left;
  }

  &__icon {
    width: 24px;
    height: 24px;
  }
}
