/* stylelint-disable no-descending-specificity */
@import "stylesheets/base";

$sizes: (
  lg $radio-lg-diameter $radio-lg-spacing $radio-lg-border-width,
  md $radio-md-diameter $radio-md-spacing $radio-md-border-width,
  sm $radio-sm-diameter $radio-sm-spacing $radio-sm-border-width
);

$variants: (
  ui $ui-500 $ui-1000 $ui-0 $ui-200 $ui-0 $ui-800 $ui-300 $ui-800 $accent-600,
  accent $ui-500 $accent-500 $ui-0 $ui-200 $ui-0 $accent-600 $ui-300 $accent-700
    $accent-600
);

.input__radio-group {
  margin-bottom: $spacing-300;

  @each $size in $sizes {
    &--#{nth($size, 1)} {
      .input__radio:checked ~ .input__radio-circle {
        &::before {
          border-width: #{nth($size, 4)};
        }
      }

      .input__radio-circle {
        height: #{nth($size, 2)};
        width: #{nth($size, 2)};

        &::before {
          height: #{nth($size, 2)};
          width: #{nth($size, 2)};
        }

        &::after {
          height: #{nth($size, 3)};
          width: #{nth($size, 3)};
        }
      }

      .input__radio-label {
        padding-left: calc(#{nth($size, 3)} + 4px);
      }
    }
  }

  @each $variant in $variants {
    &--#{nth($variant, 1)} {
      .input__radio-circle {
        position: relative;

        &::before {
          border-color: #{nth($variant, 2)};
        }

        &:active::before {
          background-color: #{nth($variant, 8)};
        }
      }

      .input__radio:checked ~ .input__radio-circle {
        &::before {
          background-color: #{nth($variant, 4)};
          border-color: #{nth($variant, 3)};
        }

        &:hover::before {
          background-color: #{nth($variant, 6)};
          border-color: #{nth($variant, 7)};
        }
      }
    }
  }

  &--horizontal {
    display: flex;

    .input__radio-label {
      flex-grow: 1;

      &:not(:last-child) {
        margin-right: $radio-gap;
      }
    }
  }

  &--vertical {
    display: flex;
    flex-direction: column;
  }

  .input__radio-label {
    /* stylelint-disable-line */
    display: flex;
    position: relative;
    align-items: center;
    font-family: $font-body;
    font-weight: normal;
    color: $ui-1000;
    margin-bottom: 10px;
    border-radius: $spacing-100;
    padding: $spacing-200;

    &--md {
      font-size: #{rem(18)};
    }

    .input__faux-background {
      display: block;
      position: absolute;
      width: 100%;
      top: -2px;
      right: 0;
      bottom: -2px;
      left: 0;
      z-index: -1;
      border: $ui-200 1px solid;
      border-radius: $spacing-100;
    }

    &:hover {
      cursor: pointer;

      .input__faux-background {
        background: $ui-100;
      }
    }

    &:focus {
      .input__faux-background {
        border: $accent-600 2px solid;
      }
    }
  }

  &.disabled {
    opacity: 0.24;
  }

  .input__radio-circle {
    position: relative;
    display: inline-block;
    margin-right: $spacing-150;

    &::before,
    &::after {
      position: absolute;
      content: "";
      border-radius: 50%;
      border-style: solid;
      border-width: $radio-border-width;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &::after {
      border-color: transparent;
      background: transparent;
      left: $radio-outline-offset;
    }
  }

  .input__radio {
    opacity: 0;
    position: absolute;
    width: 0;
    margin: 0;
    height: 0;

    &:checked ~ .input__radio-circle {
      &::before {
        border-style: solid;
      }
    }

    &:checked ~ .input__radio-text {
      color: $accent-600;
    }

    &:checked ~ .input__faux-background {
      background-color: $accent-100;
      border: $accent-300 1px solid;
    }

    &:disabled ~ .input__radio-circle {
      pointer-events: none;
    }

    &:focus ~ .input__faux-background {
      border: 0;
      box-shadow: 0 0 0 2px $accent-600, inset 0 0 0 1px $accent-600;
    }
  }
}
