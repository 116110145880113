@import "stylesheets/base";

.basket-item-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 1rem !important;
  padding: 1rem 1.5rem !important;
  border-radius: 0.75rem;
  box-shadow: 0 4px 16px 0 rgba(219, 224, 229, 0.5);

  @media screen and(min-width: $breakpoint-sm) {
    padding: 2rem 2.5rem !important;

    &__name {
      font-size: $title-md-font-size;
      letter-spacing: $title-md-letter-spacing;
      line-height: $title-md-line-height;
      margin-bottom: 1rem;
      text-decoration: none;

      &__link {
        text-decoration: none;
      }
    }

    &__price {
      font-size: $title-sm-font-size;
      letter-spacing: $title-sm-letter-spacing;
      line-height: $title-sm-line-height;
    }
  }

  &__inner {
    width: 100%;
    max-width: 450px;

    @media screen and(min-width: $breakpoint-sm) {
      max-width: 100%;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;

    * {
      margin: 0;
    }
  }

  &__qty-selector {
    &--mobile {
      margin-block: 1rem;

      @media screen and (min-width: $breakpoint-sm) {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @media screen and (min-width: $breakpoint-sm) {
        display: flex;
        align-items: center;
      }
    }
  }

  &__top-row {
    margin-inline: 0 !important;
  }

  &__image {
    img {
      object-fit: cover;
      max-width: 100%;
      max-height: 100%;
    }

    &__col {
      display: flex;
      justify-content: center;
    }
  }

  &__button-row {
    margin-block: 1rem !important;
    flex-direction: column-reverse;
    row-gap: 2rem;

    @media screen and (min-width: $breakpoint-sm) {
      margin-block: 2rem 0 !important;
      flex-direction: row;
    }
  }

  &__delete-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;

    @media screen and (min-width: $breakpoint-sm) {
      margin: 0 auto !important;
    }

    &__text {
      font-size: #{rem(17)};
      font-weight: 600;
      margin: 0;
    }
  }

  &__warning-msg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 0.5rem;
    line-height: 1.375rem;
    font-weight: 500;
    color: $negative-500;
    margin-bottom: 1rem;

    svg {
      path {
        fill: $negative-500;
      }
    }

    @media screen and(min-width: $breakpoint-sm) {
      margin-left: 1.5rem;
    }
  }

  &__warning-icon {
    flex-shrink: 0;
  }
}
