@import "stylesheets/base";

.how-it-works-section {
  &__container {
    padding-block: 1.5rem 2.5rem;
    background: $ui-0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin: 0 1rem;

    @media screen and (min-width: $breakpoint-md) {
      padding-block: 2rem 4rem;
    }
  }

  &__heading {
    justify-content: center;
    margin-inline: auto;
    text-align: center;
    color: $accent-900;
    font-weight: 600;
  }

  &__toggle {
    &__container {
      display: flex;
      padding: 0.25rem;
      width: 100%;
      max-width: 400px;
      gap: 0.25rem;
      background: $ui-100;
      border-radius: 0.75rem;
      border: 3px solid $accent-500;
    }

    &__button {
      flex: 1;
      font-size: 16px;
      transition: background-color 0.3s, color 0.3s;
      margin: 0;
      border: 0;
      outline: 0;

      &--active {
        &:disabled {
          opacity: 1;
        }
      }
    }
  }

  &__steps {
    width: fit-content;
    margin-inline: auto;
    padding-inline: 1rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;

    @media screen and (min-width: $breakpoint-sm) {
      grid-template-columns: repeat(3, 1fr);
      gap: 4rem;
    }

    @media screen and (min-width: $breakpoint-lg) {
      grid-template-columns: repeat(3, 1fr);
      padding-inline: 2rem;
    }

    @media screen and (min-width: $breakpoint-xl) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__step {
    width: 100%;
    max-width: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    svg {
      height: 176px;
    }

    &__footer {
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: fit-content;
      gap: 0.75rem;

      &__number {
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        border: 2px solid $ui-800;
        background: $ui-0;

        * {
          width: 100%;
          text-align: center;
          margin: 0;
          padding: 0;
        }
      }

      &__copy {
        flex: 1;
      }
    }
  }
}
