@import "stylesheets/base";

.start-consultation-section {
  background-color: $accent-100;
  padding-block: 3rem;

  &__container {
    max-width: $breakpoint-lg !important;
  }

  &__content-row {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media screen and (min-width: $breakpoint-md) {
      flex-direction: row;
      gap: 4rem;
    }
  }

  &__text-col {
    @media screen and (min-width: $breakpoint-md) {
      flex: 1 1 55%;
    }

    h4 {
      color: $accent-700;
    }

    h4 + h4 {
      margin-top: 1.5rem;
    }
  }

  &__cta-col {
    @media screen and (min-width: $breakpoint-md) {
      flex: 1 1 45%;
    }

    button {
      margin-top: 1rem;
      width: 100%;
    }
  }

  &__info-steps {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__info-msg {
    display: flex;
    align-items: flex-start;
    justify-content: left;
    text-align: left;
    gap: 1rem;
    line-height: 1.375rem;
    font-weight: 500;

    &__icon {
      flex: 0;
      width: 24px;
      min-width: 24px;
      height: 24px;
    }
  }

  &__heading {
    color: $accent-700;
    margin-bottom: 2rem;
    text-align: center;

    @media screen and(min-width: $breakpoint-md) {
      text-align: left;
    }
  }
}
