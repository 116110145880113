@import "stylesheets/base";

.start-consultation-cta {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2rem;
  margin: auto;
  padding: 1rem;

  border-radius: 0.75rem;
  border: 3px solid $accent-500;
  background-color: $accent-100;
  box-shadow: 0 4px 16px 0 rgba(219, 224, 229, 0.5);

  h3,
  p {
    color: $accent-700;
    margin: 0;
  }

  &__section-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__info-msg {
    display: flex;
    align-items: flex-start;
    justify-content: left;
    text-align: left;
    gap: 1rem;
    line-height: 1.375rem;
    font-weight: 500;

    &__icon {
      flex: 0;
      width: 24px;
      min-width: 24px;
      height: 24px;
    }
  }

  button {
    width: 100%;
    margin: 0;
  }
}
