@import "stylesheets/base";

.breadcrumbs {
  $root: &;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: $breakpoint-xl;
  margin-inline: auto;
  padding: $spacing-200;
  font-family: $font-heading;
  font-weight: 600;
  font-size: #{rem(19)};
  line-height: #{rem(24)};
  letter-spacing: #{rem(-0.25)};
  min-height: #{rem(64)};
  position: relative;
  z-index: 1000;

  @media screen and (min-width: $breakpoint-md) {
    padding-left: 36px;
  }

  @media screen and (min-width: $breakpoint-lg) {
    padding-left: 4rem;
  }

  @media screen and (min-width: $breakpoint-xl) {
    padding-left: $spacing-200;
  }

  &__list-item {
    display: inline-block;
    position: relative;
    padding-right: $spacing-350;
    padding-left: $spacing-100;

    &:first-of-type {
      padding-left: 0;
    }

    &:not(:last-child)::after {
      content: "";
      display: block;
      position: absolute;
      border-top: $ui-1000 2px solid;
      border-right: $ui-1000 2px solid;
      width: #{rem(8)};
      height: #{rem(8)};
      right: $spacing-50;
      top: 45%;
      transform: rotate(45deg) translate(-50%);
    }
  }

  &__link {
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }

    * {
      color: $accent-500;
      margin: 0;
      padding: 0;
    }

    &--active {
      text-decoration: underline;
      text-decoration-color: $accent-500;
    }
  }
}
