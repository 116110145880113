@import "stylesheets/base";

.file-upload {
  $root: &;

  &__input {
    display: none;
  }

  &__file-name-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: $spacing-200;
    border-top: 1px solid #dbe0e5;
    border-bottom: 1px solid #dbe0e5;
    padding-block: $spacing-200;
  }

  &__image {
    width: $spacing-500;
    margin-right: $spacing-100;
  }

  &__button-container {
    text-align: right;
    margin-top: $spacing-200;
  }

  &__button {
    display: inline-block;
    border-radius: $spacing-100;
    border: none;
    font-family: $font-heading;
    font-weight: 500;
    font-size: #{rem(19)};
    line-height: #{rem(24)};
    text-align: center;
    cursor: pointer;
    margin-bottom: $spacing-100;

    background: $accent-200;
    box-shadow: 0 0 0 1px $accent-200;
    min-width: #{rem(92)};
    min-height: #{rem(40)};

    &:hover {
      box-shadow: 0 0 0 1px $accent-300;
      background: $accent-300;
    }

    &:active {
      background: $accent-300;
      color: $accent-200;
      box-shadow: 0 0 0 1px $accent-300;
    }

    &:focus-within {
      box-shadow: 0 0 0 3px $accent-600;
    }
  }

  &__text {
    color: $accent-700;
    cursor: pointer;
    display: inline-flex;
    height: #{rem(40)};
    width: #{rem(92)};
    align-items: center;
    justify-content: center;
  }

  &__error {
    color: $negative-500;
    display: block;
  }

  &__helper-text {
    font-size: $paragraph-md-font-size;
    font-weight: $paragraph-md-font-weight;
    letter-spacing: $paragraph-md-letter-spacing;
    line-height: $paragraph-md-line-height;
    margin-top: 0.5rem;
    color: $ui-500;
    display: block;
  }
}
