@import "stylesheets/base";

.form-progress-points {
  position: relative;
  margin-top: -2px;
  margin-left: -$spacing-50;
  padding-left: $spacing-400;

  &__point {
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: -#{$spacing-250};
      transform: translate(-50%, 50%);
      top: 0;
      height: $spacing-150;
      width: $spacing-150;
      border: 2px solid $ui-800;
      border-radius: 50%;
    }

    &:not(:last-of-type)::after {
      content: "";
      display: block;
      position: absolute;
      left: -#{$spacing-250};
      transform: translateX(-50%);
      top: $spacing-300;
      bottom: 0;
      width: 1px;
      background: $ui-200;
    }

    &:first-of-type {
      &::before {
        width: 24px;
        height: 24px;
        top: 0;
        left: -#{$spacing-250};
        transform: translateX(-50%);
        background: $ui-800;
      }
    }
  }

  &__icon {
    position: absolute;
    top: 2px;
    left: -#{$spacing-250};
    transform: translateX(-50%);
  }
}
