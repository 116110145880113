/* stylelint-disable property-no-vendor-prefix */
/* stylelint-disable value-no-vendor-prefix */
@import "stylesheets/base";

.search-results {
  max-width: $breakpoint-lg !important;
  margin-inline: auto;
  padding: 1rem;

  &--no-results {
    max-width: $breakpoint-sm !important;
  }

  &__section-heading {
    margin-block: 2rem 1rem;
  }

  &__conditions {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    @media screen and (min-width: $breakpoint-md) {
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
    }
  }

  li {
    padding-left: 1rem;
    list-style: disc;
    list-style-position: inside;
  }

  &__products {
    &__list-overrides {
      ul {
        @media screen and(min-width: 400px) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @media screen and(min-width: 605px) {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        @media screen and(min-width: 1120px) {
          grid-template-columns: repeat(6, minmax(0, 1fr));
          gap: 1.5rem;
        }

        li {
          padding: 0;
        }
      }
    }
  }
}

.condition-card {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 1rem 2rem;
  gap: 4rem;
  align-items: center;
  box-shadow: 0 4px 16px 0 $ui-200;
  border-radius: 0.75rem;
  text-decoration: none;

  svg {
    path {
      fill: black;
    }
  }

  &:hover {
    background-color: $ui-100;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    transform: scale(1.02);
  }

  &:focus {
    outline: none;
    background-color: $ui-100;
    box-shadow: 0 0 0 3px $accent-500, 0 4px 16px 0 $ui-200;
  }

  &:active {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(2px); /* Depress slightly when clicked */
  }

  &__contents {
    flex: 1;
  }

  &__tagline {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin: 0;
  }
}
