@import "stylesheets/base";

.select-gp-landing {
  &__title {
    margin-bottom: $spacing-150;
  }

  &__progress-container {
    display: flex;
    justify-content: center;

    @media screen and (min-width: $breakpoint-sm) {
      margin-block: 1rem;
    }

    @media screen and (min-width: $breakpoint-md) {
      margin-block: 2rem;
    }
  }

  &__content {
    text-align: center;
  }

  &__image {
    margin: $spacing-1000 $spacing-200;
  }

  &__nhs-link {
    margin-bottom: $spacing-100;
  }
}
