@import "stylesheets/base";

.basket {
  padding: 1.5rem !important;

  &__section-heading {
    margin-bottom: 1rem;
  }

  &__section {
    margin-bottom: 2rem;

    @media screen and(min-width: $breakpoint-sm) {
      margin-bottom: 4rem;
    }
  }

  &__section-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    line-height: 1.375rem;
    font-weight: 500;

    @media screen and(min-width: $breakpoint-sm) {
      margin-left: 1.5rem;
    }
  }

  &__section-info-icon {
    flex-shrink: 0;
  }

  &__price {
    align-items: flex-end;
    justify-content: flex-end;
    gap: 0.5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    vertical-align: text-bottom;

    h2 {
      line-height: 1.2rem;
    }

    h3 {
      line-height: 1.05rem;
    }
  }

  &__checkout {
    align-items: flex-end;
    justify-content: flex-end;
  }

  &__warning-msg {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 0.5rem;
    line-height: 1.375rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  &__warning-icon {
    flex-shrink: 0;
  }

  button {
    margin: 0;
  }

  &__dropdown {
    select {
      border-radius: 0.75rem;
      border: 1px solid $ui-300;

      &:focus {
        border: none;
      }
    }
  }
}
