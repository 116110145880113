@import "stylesheets/base";

.icon-info-span {
  position: relative;
  padding: 0 0 0 $spacing-400;
  margin: 0;

  &:not(:last-child) {
    margin-bottom: $spacing-100;
  }

  &__info-icon {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
