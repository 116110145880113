@import "stylesheets/base";

.switch-patient-modal {
  $root: &;

  &__list-item {
    position: relative;
    color: $ui-1000;
    text-decoration: none;
    display: block;
    z-index: 999;
  }

  &__link {
    display: block;
    padding: $spacing-200;
    text-decoration: none;
    border: solid 1px $ui-200;
    border-radius: $spacing-150;
    margin-top: $spacing-150;

    &:focus {
      outline: 0;
    }
  }

  &__arrow {
    position: absolute;
    top: $spacing-200;
    right: $spacing-100;

    svg {
      path {
        fill: $ui-1000;
      }
    }
  }

  &__button {
    margin-top: $spacing-300;
  }
}
