@import "stylesheets/base";

.hero-section {
  &__container {
    padding-top: $spacing-400;
  }

  &__row {
    flex-direction: column-reverse;
  }

  &__heading {
    margin: 0;
    font-weight: 600;
    text-align: left;
  }

  &__info {
    padding: $spacing-300;
    text-align: left;
  }

  &__body {
    margin: 1rem 0 0;
    font-size: $title-md-font-size;
    font-weight: $title-md-font-weight;
    line-height: $title-md-line-height;
    letter-spacing: $title-md-letter-spacing;
  }

  &__tagline {
    margin: 0;

    &__container {
      margin-top: 1.5rem;
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      align-items: center;
      justify-content: flex-start;

      svg {
        height: 1.5rem;
        width: 1.5rem;
        flex-shrink: 0;
      }
    }
  }

  &__ctas {
    margin-top: 1.5rem;

    > button {
      width: 100%;
      padding-inline: $spacing-200;
      font-size: $label-md-font-size;
      margin-bottom: 0;

      @media screen and (min-width: $breakpoint-md) {
        width: auto;
        min-width: 200px;
        height: 3rem;
      }

      &:first-child {
        margin-bottom: 1rem;
        margin-right: 1rem;

        @media screen and (min-width: $breakpoint-md) {
          margin-bottom: 0;
        }
      }
    }
  }

  &__image {
    height: 100%;
    margin-inline: auto;
    max-width: 100%;

    &__container {
      text-align: center;
      width: 100%;
      height: 100%;
      max-height: 300px;

      @media screen and (min-width: $breakpoint-lg) {
        max-height: 350px;
      }
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    &__row {
      flex-direction: row;
    }

    &__info {
      padding: 0;
      text-align: start;

      &-col {
        padding-left: 3rem !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
