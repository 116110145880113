@import "stylesheets/base";

.menu-items {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 80px;
  padding: 32px;

  button {
    margin: 0 10px;
    height: 3rem;
  }

  &__divider {
    margin-inline: auto;
    min-width: 100px;
    border-bottom: 2px solid $ui-300;
    height: 1px;
  }

  &__button-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.8rem;
  }

  &__button-with-chevron {
    display: flex;
    align-items: center;
  }

  &__back-button {
    padding-right: 74px;

    &--condition {
      padding-right: 44px;
    }
  }

  &__shop-button {
    padding-right: 46px;
  }

  &--active {
    span {
      color: $accent-500;
      text-decoration: underline;

      svg {
        path {
          fill: $accent-500;
        }
      }
    }
  }
}
