@import "stylesheets/base";

.prescription-landing {
  padding: $spacing-250 0;

  &__title {
    margin-bottom: $spacing-200;
  }

  &__text {
    margin-bottom: $spacing-300;
  }

  &__no-orders-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    border: 1px solid $ui-200;
    border-radius: $spacing-150;
    padding: $spacing-200;
    margin-bottom: $spacing-150;

    @media screen and (min-width: $breakpoint-md) {
      margin: $spacing-300 auto;
    }
  }

  &__icon {
    margin-top: #{rem(50)};
    margin-bottom: #{rem(14)};
  }

  &__no-orders-text {
    max-width: #{rem(263)};
    margin-bottom: $spacing-600;
    text-align: center;
    color: $ui-500;
  }

  &__content {
    text-align: center;
  }

  &__button {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  &__dependant {
    width: 100%;
    margin-bottom: 0;
  }

  &__info {
    color: $ui-500;
    text-align: center;
  }

  &__hr {
    border: 0;
    border-bottom: 1px solid $ui-200;
    margin: $spacing-400 $spacing-400;
  }
}
